import * as Types from 'src/core/redux/cloud-storage/cloud-storage.type';
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';

const cloudStorageRepository = Repositories[RepositoryName.CLOUD_STORAGE];

export const actUploadFile = (image: any, callback: (objectName: string) => void, bucketName?: string) => (dispatch: any) => {
    dispatch({
        type: Types.UPLOAD_FILE_REQUEST
    });
    cloudStorageRepository.getUploadSignedUrl(image.name, bucketName).then(res => {
        const {objectName, url} = res.data;
        cloudStorageRepository.uploadFile(image, url)
            .then(() => {
                dispatch({
                    type: Types.UPLOAD_FILE_SUCCESS,
                    payload: objectName
                });
                callback(objectName);
            })
            .catch((error) => {
                alert('There was an error during upload, please try again later.');
                dispatch({
                    type: Types.UPLOAD_FILE_FAILURE,
                    payload: error
                })
            });
    });
}
