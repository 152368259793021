import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import appReducers from 'src/core/redux/app-reducers';
import { callAPIMiddleware } from 'src/core/utils/redux';

const middlewares = [thunk, callAPIMiddleware];

const appStore = createStore(
    appReducers,
    applyMiddleware(...middlewares),
);

export default appStore;
