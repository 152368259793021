import { AnyAction } from 'redux';
import * as Types from 'src/core/redux/cloud-storage/cloud-storage.type';
import { createReducer } from 'src/core/utils/redux';
import CloudStorageState from 'src/core/models/redux/cloud-storage-state.model';

const initialState: CloudStorageState = {
    uploadFile: {
        objectName: null,
        error: null,
        isLoading: false,
    },
};

const cloudStorageReducer = createReducer(initialState, {
    [Types.UPLOAD_FILE_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            uploadFile: {
                response: null,
                error: null,
                isLoading: true,
            }
        };
    },
    [Types.UPLOAD_FILE_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            uploadFile: {
                response: action.payload,
                error: null,
                isLoading: false,
            }
        };
    },
    [Types.UPLOAD_FILE_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            uploadFile: {
                ...state.uploadFile,
                error: action.payload,
                isLoading: false,
            }
        };
    },
});

export default cloudStorageReducer;
