import React, { Component } from 'react';
import { MDBIcon, MDBNavbar, MDBNavbarBrand, MDBNavbarNav } from 'mdbreact';
import * as COMMON from 'src/core/utils/common';
import * as CONST from 'src/core/utils/constants';
import history from 'src/core/utils/history';
import { connect } from 'react-redux';
import * as CommonAction from 'src/core/redux/common/common.action';
import { bindActionCreators } from 'redux';

type Props = {
    toggle: boolean,
    className: string,
    onSideNavToggleClick: () => void,
    resetUserInfo: () => (dispatch: any) => void,
}

class TopNavigation extends Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {
            userInfo: null
        }
    }

    componentDidMount() {
        const userInfo = COMMON.getLocalStorage(CONST.LocalStorage.USER_INFO);
        this.setState({userInfo});
    }

    handleLogout = () => {
        this.props.resetUserInfo();

        COMMON.clearLocalStorage();
        history.push(CONST.AppURI.LOGIN);
    }

    render(): JSX.Element {
        const navStyle = {
            paddingLeft: this.props.toggle ? '16px' : '240px',
            transition: 'padding-left .3s'
        };
        return (
            <div className='navy-skin'>
                <MDBNavbar
                    className='flexible-MDBNavbar'
                    expand='md'
                    scrolling
                    fixed='top'
                    style={{ zIndex: 3 }}
                >
                    <div
                        onClick={this.props.onSideNavToggleClick}
                        key='sideNavToggleA'
                        style={{
                            lineHeight: '32px',
                            marginLeft: '1em',
                            verticalAlign: 'middle',
                            cursor: 'pointer'
                        }}
                    >
                        <MDBIcon icon='bars' color='white' size='lg' />
                    </div>

                    <MDBNavbarBrand href='/' style={navStyle}>
                    </MDBNavbarBrand>
                    <MDBNavbarNav expand='sm' right style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <div className="mr-3">{this.state.userInfo?.name}</div>
                        <div className="btn-link text-white" style={{cursor: 'pointer', padding: 10}} onClick={this.handleLogout}>Đăng xuất</div>
                    </MDBNavbarNav>
                </MDBNavbar>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
    resetUserInfo: CommonAction.actResetUserInfo,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TopNavigation);
