import React, { useEffect, useMemo, useState } from 'react';
import { MDBBtn, MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdbreact';
import { useParams } from 'react-router-dom';
import DocumentModel from 'src/core/models/document.model';
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import Logo from 'src/shared/assets/img/vietchaulogo.png';
import QRLogo from 'src/shared/assets/img/qr_logo.png';
import 'src/features/pages/public-document/public-document.style.scss';
import { downloadFile } from 'src/core/utils/common';

const QRCode = require('qrcode.react');

const PublicDocumentContainer = (): JSX.Element => {
    const {documentCode} = useParams<{ documentCode: string }>();
    const [document, setDocument] = useState<DocumentModel | null>(null);

    useEffect(() => {
        if (documentCode) {
            const commonRepository = Repositories[RepositoryName.COMMON];
            const code = documentCode.toUpperCase().replaceAll('_', '/');
            commonRepository.getDocumentForGuest(code).then((res) => {
                setDocument(res.data);
            });
        }
    }, []);

    const renderFileElement = useMemo(() => {
        if (document?.file) {
            const fileName = decodeURIComponent(document.file.split('/').slice(-1).pop() || '');
            return (
                <table className="mx-auto mt-3">
                    <tbody>
                        <tr>
                            <td colSpan={2} className='file-table-td'><div className='p-2 font-weight-normal text-truncate' style={{width: 300}}>{fileName}</div></td>
                        </tr>
                        <tr>
                            <td width='50%' className='file-table-td'>
                                <MDBBtn flat rounded className='my-0 mx-1 p-2' onClick={() => fileName && downloadFile(document.file, fileName)}>
                                    <MDBIcon icon='download' size='lg' className='text-primary'/>
                                </MDBBtn>
                            </td>
                            <td className='file-table-td'>
                                <a href={document.file} target="_blank" rel="noreferrer" className='my-0 mx-1 p-2'>
                                    <MDBIcon icon='eye' size='lg' className='text-primary'/>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            );
        }
    }, [document]);

    return (
        <div id='public-document'>
            <MDBContainer>
                <MDBRow>
                    <MDBCol sm='12' md='10' lg='8' xl='6' className='mt-5 mx-auto'>
                        <div className="text-center mb-4">
                            <p className="h4 company-name">VIETCHAU CONTROL</p>
                        </div>
                        {
                            document &&
                            <div className="text-center">
                                <QRCode
                                    id='qrCode'
                                    value={`${window.location.origin.toString()}/public/document/${documentCode}`}
                                    imageSettings={{src: QRLogo, width: 115, height: 115}}
                                    size={256}
                                    level='H'
                                />
                                <p className="h2 pt-3 text-primary document-code">{document.code}</p>
                                {renderFileElement}
                            </div>
                        }
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

export default PublicDocumentContainer;
