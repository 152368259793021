import React, { useEffect } from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCol, MDBContainer, MDBInput, MDBRow, MDBSpinner, MDBView } from 'mdbreact';
import 'src/features/pages/login/login.style.scss';
import * as CommonAction from 'src/core/redux/common/common.action';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import CommonStateType from 'src/core/models/common-state.model';
import PickDeep from 'src/core/models/pick-deep.modal';
import { useHistory } from 'react-router-dom';
import * as CONST from 'src/core/utils/constants';
import _ from 'lodash';
import Logo from 'src/shared/assets/img/vietchaulogo.png';

const Login = (): JSX.Element => {

    const {register, unregister, reset, handleSubmit, setValue, trigger, formState: {errors}} = useForm();
    const intl = useIntl();
    const history = useHistory();
    const dispatch = useDispatch();
    const loginSelector = useSelector<CommonStateType, PickDeep<CommonStateType, 'login'>>((state: any) => state.commonReducer.login);
    const userInfoSelector = useSelector<CommonStateType, PickDeep<CommonStateType, 'userInfo'>>((state: any) => state.commonReducer.userInfo);

    const registerForm = () => {
        register('fUserName', {
            required: intl.formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: intl.formatMessage({id: 'LOGIN.LABEL.USER_NAME'})}),
            maxLength: {
                value: 255,
                message: intl.formatMessage({id: 'MESSAGE.ERR_0011'}, {
                    _0: intl.formatMessage({id: 'LOGIN.LABEL.USER_NAME'}),
                    _1: 255
                }),
            }
        });

        register('fPassword', {
            required: intl.formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: intl.formatMessage({id: 'LOGIN.LABEL.PASS_WORD'})}),
            maxLength: {
                value: 25,
                message: intl.formatMessage({id: 'MESSAGE.ERR_0011'}, {
                    _0: intl.formatMessage({id: 'LOGIN.LABEL.PASS_WORD'}),
                    _1: 25
                }),
            }
        });
    }

    const unRegisterForm = () => {
        unregister(['fUserName', 'fPassword']);

        reset({
            fUserName: null,
            fPassword: null
        });
    }

    useEffect(() => {
        registerForm();

        return () => {
            unRegisterForm();
        }
    }, [])

    useEffect(() => {
        if (_.isEmpty(userInfoSelector.data)) return;
        history.push(CONST.AppURI.INDEX);

    }, [userInfoSelector])

    const submitHandler = (values: any) => {
        dispatch(CommonAction.actLogin(values['fUserName'], values['fPassword']));
    }

    const handleChangeInput = async (e: any) => {
        setValue(e.target.name, e.target.value);
        await trigger(e.target.name);
    }

    return (
        <div className='classic-form-page' id='login'>
            <form className='needs-validation' id='fLogin' noValidate>
                <MDBContainer>
                    <MDBRow>
                        <MDBCol sm='12' md='10' lg='8' xl='6'  className='mt-5 mx-auto'>
                            <MDBCard className='bg-white'>
                                <MDBCardBody>
                                    <div className="text-center mb-4">
                                        <img src={Logo} alt="thumbnail" style={{width: 200}} className="mx-auto mb-3"/>
                                        <p className="h1 company-name">VIETCHAU CONTROL</p>
                                    </div>

                                    <MDBRow>
                                        <MDBCol size='10' className='mx-auto'>
                                            <div className="grey-text">
                                                <MDBInput
                                                    type='text'
                                                    name='fUserName'
                                                    label={intl.formatMessage({id: 'LOGIN.LABEL.USER_NAME'})}
                                                    icon='user'
                                                    onChange={handleChangeInput}
                                                >
                                                    <div className="custom-invalid-feedback ml-4 pl-3">
                                                        {errors['fUserName']?.message}
                                                    </div>
                                                </MDBInput>
                                                <MDBInput
                                                    type='password'
                                                    name='fPassword'
                                                    label={intl.formatMessage({id: 'LOGIN.LABEL.PASS_WORD'})}
                                                    icon='lock'
                                                    onChange={handleChangeInput}
                                                >
                                                    <div className="custom-invalid-feedback ml-4 pl-3">
                                                        {errors['fPassword']?.message}
                                                    </div>
                                                </MDBInput>
                                            </div>
                                        </MDBCol>
                                    </MDBRow>
                                    <div className='text-center mt-3 black-text'>
                                        <MDBBtn
                                            color='primary' size='lg'
                                            onClick={handleSubmit(submitHandler)}>
                                            {loginSelector.isLoading ?
                                                <MDBSpinner small yellow={true}/> :
                                                intl.formatMessage({id: 'LOGIN.BUTTON.LOGIN'})
                                            }
                                        </MDBBtn>
                                        <div className='text-center'>
                                            {loginSelector.error && <span
                                                className='text-danger'>{intl.formatMessage({id: 'LOGIN.MESSAGE.FAILURE'})}</span>}
                                        </div>
                                    </div>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </form>
        </div>
    );
}

export default Login;
