import React from 'react';
import { AppURI } from 'src/core/utils/constants';
import { MDBIcon, MDBSideNav, MDBSideNavLink, MDBSideNavNav } from 'mdbreact';
import VietchauLogo from 'src/shared/assets/img/vietchaulogo_rounded.png';
import _ from 'lodash';

type Props = {
    onLinkClick: () => void,
    breakWidth: number,
    triggerOpening: boolean,
    style: Record<string, unknown>
}

class SideNavigation extends React.Component<Props> {
    // render MDBSideNav Link
    rSNL(to: string, text: string, icon = '', topLevel = true): JSX.Element {
        return (
            <MDBSideNavLink topLevel={topLevel} to={to} onClick={this.props.onLinkClick}>
                {!_.isEmpty(icon) && <MDBIcon icon={`${icon} mr-2`} />}
                <div className='font-weight-normal'>{text}</div>
            </MDBSideNavLink>
        );
    }

    render(): JSX.Element {
        return (
            <div className='navy-skin'>
                <MDBSideNav
                    logo={VietchauLogo}
                    mask={'strong' as any}
                    fixed
                    breakWidth={this.props.breakWidth}
                    triggerOpening={this.props.triggerOpening}
                    style={{ transition: 'padding-left .3s' }}
                >
                    <MDBSideNavNav>
                        {this.rSNL(AppURI.REQUESTS, 'DANH SÁCH YÊU CẦU')}
                        {this.rSNL(AppURI.DOCUMENTS, 'DANH SÁCH CHỨNG THƯ')}
                    </MDBSideNavNav>
                </MDBSideNav>
            </div>
        );
    }
}

export default SideNavigation;
