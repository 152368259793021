import * as Types from 'src/core/redux/request-type/request-type.type';
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';

const requestRepository = Repositories[RepositoryName.REQUEST];

export const actGetListRequestTypes = () => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_REQUEST_TYPES_REQUEST, Types.GET_LIST_REQUEST_TYPES_SUCCESS, Types.GET_LIST_REQUEST_TYPES_FAILURE],
        callAPI: () => requestRepository.getListRequestTypes(),
    });
};
