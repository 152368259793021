import { AnyAction } from 'redux';
import * as Types from 'src/core/redux/request/request.type';
import { createReducer } from 'src/core/utils/redux';
import PagingResponse from 'src/core/models/paging/paging-response.model';
import RequestState from 'src/core/models/redux/request-state.model';
import RequestModel from 'src/core/models/request.model';
import _ from 'lodash';
import ResponseCustom from 'src/core/models/responses/response-custom.model';

const initialState: RequestState = {
    requests: {
        data: new PagingResponse<RequestModel>(),
        error: null,
        isLoading: false,
    },
    createRequest: {
        response: null,
        error: null,
        isLoading: false,
    },
    requestDetails: {
        data: null,
        error: null,
        isLoading: false,
    },
    updateRequest: {
        response: null,
        error: null,
        isLoading: false,
    },
    deleteDocumentOfRequest: {
        response: null,
        error: null,
        isLoading: false,
    },
};

const requestReducer = createReducer(initialState, {
    [Types.GET_LIST_REQUESTS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            requests: {
                ...state.requests,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_REQUESTS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            requests: {
                data: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.GET_LIST_REQUESTS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            requests: {
                ...state.requests,
                error: action.payload,
                isLoading: false
            }
        };
    },
    [Types.CREATE_REQUEST_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createRequest: {
                ...state.createRequest,
                isLoading: true,
            }
        };
    },
    [Types.CREATE_REQUEST_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createRequest: {
                response: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.CREATE_REQUEST_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createRequest: {
                ...state.createRequest,
                error: action.payload,
                isLoading: false
            }
        };
    },
    [Types.GET_REQUEST_DETAILS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            requestDetails: {
                ...state.requestDetails,
                isLoading: true,
            }
        };
    },
    [Types.GET_REQUEST_DETAILS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            requestDetails: {
                data: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.GET_REQUEST_DETAILS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            requestDetails: {
                ...state.requestDetails,
                error: action.payload,
                isLoading: false
            }
        };
    },
    [Types.UPDATE_REQUEST_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            updateRequest: {
                ...state.createRequest,
                isLoading: true,
            }
        };
    },
    [Types.UPDATE_REQUEST_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            updateRequest: {
                response: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.UPDATE_REQUEST_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            updateRequest: {
                ...state.updateRequest,
                error: action.payload,
                isLoading: false
            }
        };
    },
    [Types.DELETE_DOCUMENT_OF_REQUEST_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            deleteDocumentOfRequest: {
                ...state.deleteDocumentOfRequest,
                isLoading: true,
            }
        };
    },
    [Types.DELETE_DOCUMENT_OF_REQUEST_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        const deletedId = action.payload.id;
        const documents = _.cloneDeep(state.requestDetails.data?.documents);
        const deletedIndex = _.findIndex(documents, item => item.id === deletedId);
        if (deletedIndex >= 0) {
            documents?.splice(deletedIndex, 1);
        }

        return {
            ...state,
            requestDetails: {
                ...state.requestDetails,
                data: {
                    ...state.requestDetails.data,
                    documents
                },
            },
            deleteDocumentOfRequest: {
                response: action.payload.response,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.DELETE_DOCUMENT_OF_REQUEST_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            deleteDocumentOfRequest: {
                ...state.deleteDocumentOfRequest,
                error: action.payload,
                isLoading: false
            }
        };
    },
})

export default requestReducer;
