import React from 'react';
import { MDBBtn, MDBModal, MDBModalBody, MDBModalFooter } from 'mdbreact';

type Props = {
    isVisible: boolean,
    setIsVisible: (value: boolean) => void,
    message: string;
    code?: string,
    onClickOk: () => void,
}

const CreateSuccessModal = (props: Props): JSX.Element => {
    return (
        <MDBModal
            isOpen={props.isVisible}
            fullHeight={false}
            inline={false}
            noClickableBodyWithoutBackdrop={false}
            overflowScroll
            size='sm'
        >
            <MDBModalBody>
                <div className='mt-3 text-center'>{props.message}</div>
                {props.code && <h3 className='mt-3 mb-3 text-center font-weight-normal text-primary'>{props.code}</h3>}
            </MDBModalBody>
            <MDBModalFooter>
                <MDBBtn
                    className='mx-auto'
                    color='success'
                    onClick={() => {
                        props.onClickOk();
                        props.setIsVisible(false);
                    }}
                >
                    OK
                </MDBBtn>
            </MDBModalFooter>
        </MDBModal>
    );
}

export default CreateSuccessModal;
