import { ApiURI } from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import PagingRequest from 'src/core/models/paging-request.model';
import PagingResponse from 'src/core/models/paging/paging-response.model';
import RequestModel from 'src/core/models/request.model';
import RequestType from 'src/core/models/request-type.model';
import CreateRequestRequest from 'src/core/models/requests/create-request-request.model';
import BaseResponse from 'src/core/models/responses/base-response.model';
import CreateDocumentRequest from 'src/core/models/requests/create-document-request.model';
import DocumentModel from 'src/core/models/document.model';
import ResponseCustom from 'src/core/models/responses/response-custom.model';
import UpdateDocumentRequest from 'src/core/models/requests/update-document-request.model';
import UpdateRequestRequest from 'src/core/models/requests/update-request-request.model';

export default class RequestRepository extends BaseRepository {
    constructor() {
        super(ApiURI.REQUESTS);
    }

    getListRequests(paging: PagingRequest, queryParams = {}) {
        return this.pagination<PagingResponse<RequestModel>>({pageId: paging.pageId, sortBy: 'id', sortType: 'DESC'}, ApiURI.REQUESTS, queryParams);
    }

    getListRequestTypes() {
        return this.getAll<RequestType>(ApiURI.REQUEST_TYPES);
    }

    createRequest(request: CreateRequestRequest) {
        return this.create<BaseResponse>(request);
    }

    getRequestDetails(id: number) {
        return this.getById(id);
    }

    updateRequest(id: number, request: UpdateRequestRequest) {
        return this.update<ResponseCustom>(id, request);
    }

    deleteRequest(id: number) {
        return this.delete<ResponseCustom>(id);
    }

    getListDocuments(paging: PagingRequest, queryParams = {}) {
        return this.pagination<PagingResponse<DocumentModel>>({pageId: paging.pageId, sortBy: 'date', sortType: 'DESC'}, ApiURI.DOCUMENTS, queryParams);
    }

    getDocumentDetails(id: number) {
        return this.getById(id, ApiURI.DOCUMENTS);
    }

    createDocument(request: CreateDocumentRequest) {
        return this.create<BaseResponse>(request, ApiURI.DOCUMENTS);
    }

    deleteDocument(id: number) {
        return this.delete<ResponseCustom>(id, ApiURI.DOCUMENTS)
    }

    updateDocument(id: number, request: UpdateDocumentRequest) {
        return this.update<ResponseCustom>(id, request, ApiURI.DOCUMENTS)
    }
}
