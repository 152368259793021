import React, { useEffect, useMemo, useState } from 'react';
import { Route } from 'react-router-dom';
import SideNavigation from 'src/shared/components/side-navigation/side-navigation.component';
import TopNavigation from 'src/shared/components/top-navigation/top-navigation.component';

type State = {
    toggle: boolean,
    windowWidth: number,
    sideNavToggled: boolean,
    breakWidth: number
}

type Props = {
    component: (props: any) => JSX.Element,
    withNav: boolean | undefined,
    key: string,
    exact: boolean,
    path: string
}

const RouteWithLayout = ({ component, withNav = true, ...rest }: Props): JSX.Element => {
    const initialState: State = {
        toggle: false,
        windowWidth: 0,
        sideNavToggled: false,
        breakWidth: 768
    };

    const [state, setState] = useState(initialState);

    const handleResize = () => {
        setState({
            ...state,
            windowWidth: window.innerWidth
        });
    };

    const toggleSideNav = () => {
        if (state.windowWidth < state.breakWidth) {
            setState({
                ...state,
                sideNavToggled: !state.sideNavToggled
            });
        }
    };

    useEffect(() => {
        handleResize();
    }, [])

    useEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, [state.windowWidth])


    const dynamicLeftPadding = useMemo(() => {
        return {
            paddingLeft: state.windowWidth > state.breakWidth ? '240px' : '0'
        };
    }, [state.windowWidth])

    const mRoute = useMemo(() => {
        return <Route
            {...rest}
            component={(matchProps: Record<string, unknown>) => (
                component(matchProps)
            )}
        />
    }, [])

    return (
        <>
            {
                withNav ?
                    <div className='app'>
                        <div>
                            <SideNavigation
                                breakWidth={state.breakWidth}
                                style={{ transition: 'all .3s' }}
                                triggerOpening={state.sideNavToggled}
                                onLinkClick={toggleSideNav}
                            />
                        </div>
                        <div className='flexible-content white-skin'>
                            <TopNavigation
                                toggle={state.windowWidth < state.breakWidth}
                                onSideNavToggleClick={toggleSideNav}
                                className='white-skin'
                            />
                            <main style={{ ...dynamicLeftPadding, marginTop: '6rem' }}>
                                {mRoute}
                            </main>
                        </div>
                    </div>
                    :
                    mRoute
            }
        </>
    )
}

export default React.memo(RouteWithLayout);
