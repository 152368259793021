import React, { Component } from 'react';
import 'src/features/pages/home/home.style.scss';
import { connect } from 'react-redux';
import { injectIntl, IntlShape } from 'react-intl';
import { bindActionCreators } from 'redux';
import CommonStateType from 'src/core/models/common-state.model';
import Agent from 'src/core/models/agent.model';

type Props = {
    login: (cb?: (agents: Agent[]) => void) => (dispatch: any) => void,
    intl: IntlShape,
    common: CommonStateType
}

class Home extends Component<Props> {
    render() {
        return (
            <div className='text-center'>
                <h2>Home Page</h2>
            </div>
        );
    }
}


const mapStateToProps = (state: any) => ({
    common: state.commonReducer,
});

const mapDispatchToProps = (dispatch: any) => bindActionCreators({
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Home));
