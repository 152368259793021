export default class CreateDocumentRequest {
    requestId: number;
    date: string;
    file: string;

    constructor(requestId: number, date: string, file: string) {
        this.requestId = requestId;
        this.date = date;
        this.file = file;
    }
}
