import { ApiURI, PRIVATE_BUCKET } from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import { AxiosRequestConfig } from 'axios';

export default class CloudStorageRepository extends BaseRepository {
    constructor() {
        super(ApiURI.TODO);
    }

    getUploadSignedUrl(fileName: string, bucketName = PRIVATE_BUCKET) {
        return this.query<{ objectName: string, url: string }>({fileName, bucketName}, ApiURI.GET_UPLOAD_SIGNED_URL);
    }

    uploadFile(file: File, url: string) {
        const config: AxiosRequestConfig = {
            method: 'PUT',
            url,
            headers: {
                'Content-Type': file.type
            },
            data: file
        };
        return this.customConfig(config);
    }
}
