import { AnyAction } from 'redux';
import * as Types from 'src/core/redux/user/user.type';
import { createReducer } from 'src/core/utils/redux';
import UserState from 'src/core/models/redux/user-state.model';

const initialState: UserState = {
    appUser: {
        data: null,
        error: null,
        isLoading: false
    }
};

const userReducer = createReducer(initialState, {
    [Types.GET_APP_USER_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            appUser: {
                data: null,
                error: null,
                isLoading: true
            }
        };
    },
    [Types.GET_APP_USER_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            appUser: {
                data: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.GET_APP_USER_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            appUser: {
                data: null,
                error: action.payload,
                isLoading: false
            }
        };
    },
    [Types.GET_APP_USER_RESET]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            appUser: initialState.appUser
        };
    },
})

export default userReducer;
