import { AnyAction } from 'redux';
import CommonStateType from 'src/core/models/common-state.model';
import * as Types from 'src/core/redux/common/common.type';
import { createReducer } from 'src/core/utils/redux';

const initialState: CommonStateType = {
    login: {
        data: {},
        error: null,
        isLoading: false
    },
    userInfo: {
        data: null,
        error: null,
        isLoading: false
    }
};

const commonReducer = createReducer(initialState, {
    [Types.PERFORM_LOGIN_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            login: {
                data: [],
                error: null,
                isLoading: true
            }
        };
    },
    [Types.PERFORM_LOGIN_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            login: {
                data: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.PERFORM_LOGIN_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            login: {
                ...state.login,
                error: action.payload,
                isLoading: false
            }
        };
    },
    [Types.GET_USER_INFO_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            userInfo: {
                data: null,
                error: null,
                isLoading: true
            }
        };
    },
    [Types.GET_USER_INFO_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            userInfo: {
                data: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.GET_USER_INFO_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            userInfo: {
                data: null,
                error: action.payload,
                isLoading: false
            }
        };
    },
    [Types.GET_USER_INFO_RESET]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            userInfo: initialState.userInfo
        };
    },
})

export default commonReducer;
