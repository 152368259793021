import React, { useEffect, useMemo } from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBSpinner } from 'mdbreact';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import RequestInformation from 'src/features/components/request/request-information/request-information.component';
import { actGetDocumentDetails } from 'src/core/redux/document/document.action';
import { DATE_FORMAT, DocumentStatus, RequestStatus } from 'src/core/utils/constants';
import moment from 'moment';

const DocumentDetailsContainer = (): JSX.Element => {
    const {documentId} = useParams<{ documentId: string }>();
    const dispatch = useDispatch();
    const documentDetailsSelector = useSelector((state: any) => state.documentReducer.documentDetails);
    const documentDetails = documentDetailsSelector.data;

    useEffect(() => {
        if (documentId) {
            dispatch(actGetDocumentDetails(Number(documentId)));
        }
    }, []);

    const renderFileElement = useMemo(() => {
        if (documentDetails?.file) {
            const fileName = decodeURIComponent(documentDetails.file.split('/').slice(-1).pop() || '');
            return (
                <>
                    <MDBCol md="2">
                        <div className="font-weight-normal">File</div>
                    </MDBCol>
                    <MDBCol md="10">
                        <a href={documentDetails.file} target="_blank" rel="noreferrer"
                            className='btn-link text-primary'>{fileName}</a>
                    </MDBCol>
                </>
            );
        }
    }, [documentDetails]);

    return (
        <MDBContainer>
            <h1 className='page-title mb-4'>Chi tiết chứng thư</h1>
            {!documentDetails || documentDetailsSelector.isLoading ?
                <div className='m-5 text-center'>
                    <MDBSpinner/>
                </div>
                :
                <div>
                    <MDBRow>
                        <MDBCol size='12' className='mx-auto'>
                            <RequestInformation data={documentDetails.request}/>
                            <div className="font-weight-normal mt-4">THÔNG TIN CHỨNG THƯ</div>
                            <MDBRow className='mt-2'>
                                <MDBCol md="2">
                                    <div className="font-weight-normal">Số chứng thư</div>
                                </MDBCol>
                                <MDBCol md="10">
                                    <div className='font-weight-bold text-primary'>{documentDetails.code}</div>
                                </MDBCol>
                                <MDBCol md="2">
                                    <div className="font-weight-normal">Ngày chứng thư</div>
                                </MDBCol>
                                <MDBCol md="10">
                                    <div>{moment(documentDetails.date).format(DATE_FORMAT)}</div>
                                </MDBCol>
                                <MDBCol md="2">
                                    <div className='font-weight-normal'>Trạng thái</div>
                                </MDBCol>
                                <MDBCol md="10">
                                    <div>{DocumentStatus[documentDetails.status]}</div>
                                </MDBCol>
                                {renderFileElement}
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                </div>
            }
        </MDBContainer>
    );
}

export default DocumentDetailsContainer;
