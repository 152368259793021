import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBDatePicker,
    MDBIcon,
    MDBInput,
    MDBRow,
    MDBSpinner,
} from 'mdbreact';
import { AppURI, DATE_FORMAT, DocumentStatus } from 'src/core/utils/constants';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { downloadQR, formatDateForApi } from 'src/core/utils/common';
import Pagination from 'src/shared/components/pagination/pagination.component';
import 'src/features/pages/document/document.style.scss';
import { actGetListDocuments } from 'src/core/redux/document/document.action';
import DocumentModel from 'src/core/models/document.model';
import { generatePath } from 'react-router';
import QRLogo from 'src/shared/assets/img/qr_logo.png';
import { NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';

const QRCode = require('qrcode.react');

type DataTable = {
    no: number,
    code: JSX.Element;
    date: JSX.Element;
    companyName: string;
    qr: JSX.Element;
    status: JSX.Element;
    [rest: string]: any;
}

const columns = [
    {
        label: 'No.',
        field: 'no',
    },
    {
        label: 'Số chứng thư',
        field: 'code',
        attributes: {
            className: 'text-center'
        }
    },
    {
        label: 'Ngày chứng thư',
        field: 'date',
        attributes: {
            className: 'text-center'
        }
    },
    {
        label: 'Khách hàng yêu cầu',
        field: 'companyName',
    },
    {
        label: 'QR',
        field: 'qr'
    },
    {
        label: 'Trạng thái',
        field: 'status',
        attributes: {
            className: 'text-center'
        }
    },
    {
        label: 'Chỉnh sửa/Copy',
        field: 'commands',
    },
]

const DocumentContainer = (): JSX.Element => {
    const {formatMessage} = useIntl();
    const history = useHistory();

    const dispatch = useDispatch();
    const documentsSelector = useSelector((state: any) => state.documentReducer.documents);
    const documents = documentsSelector.data;
    const [dataTable, setDataTable] = useState<DataTable[]>([]);
    const [queryParams, setQueryParams] = useQueryParams({
        keyword: StringParam,
        fromDate: StringParam,
        toDate: StringParam,
    });
    const [page, setPage] = useQueryParam('page', NumberParam);
    const [selectedCode, setSelectedCode] = useState('');

    useEffect(() => {
        onPageChanged(page || 1);
    }, [queryParams]);

    useEffect(() => {
        renderDataRow(documents.document);
    }, [documents.document]);

    const formattedCode = useMemo(() => {
        return (selectedCode.replaceAll('/', '_'));
    }, [selectedCode]);

    const onPageChanged = (activePage: number) => {
        setPage(activePage);
        const params = {
            pageId: activePage - 1,
        }
        dispatch(actGetListDocuments(params, queryParams));
    }

    const changeQueryParams = (name: string, value: string | null) => {
        setQueryParams({
            ...queryParams,
            [name]: value ? value : undefined,
        });
        setPage(1);
    };

    const onClickCopy = (requestId: number) => {
        history.push({
            pathname: AppURI.CREATE_DOCUMENT,
            state: {
                requestId
            }
        });
    }

    const onClickDownloadQR = async (code: string) => {
        await setSelectedCode(code);
        const reformatCode = code.replaceAll('/', '_');
        downloadQR(reformatCode);
    }

    const onClickUpdate = (documentId: number) => {
        history.push({
            pathname: AppURI.UPDATE_DOCUMENT,
            state: {
                documentId
            }
        })
    }

    const navigateToDocumentDetails = (documentId: number) => {
        history.push({
            pathname: generatePath(AppURI.DOCUMENT_DETAILS, {documentId})
        })
    }

    const renderDataRow = (documentList: DocumentModel[]) => {
        const data: DataTable[] = [];
        documentList?.forEach((item: DocumentModel, index: number) => {
            const row = {
                no: documents.pageId * documents.itemsPerPage + index + 1,
                code: <div className='text-center'><a className='btn-link text-primary' onClick={() => navigateToDocumentDetails(item.id)}>{item.code}</a></div>,
                date: <div className='text-center'>{item.date ? moment(item.date).format(DATE_FORMAT) : ''}</div>,
                companyName: item.companyName,
                qr: <MDBBtn flat rounded className='my-0 mx-1 p-2' onClick={() => onClickDownloadQR(item.code)}>
                    <MDBIcon icon='download' size='lg' className='text-primary'/>
                </MDBBtn>,
                status: <div className='text-center'>{DocumentStatus[item.status]}</div>,
                commands: <div>
                    <MDBBtn flat rounded className='my-0 mx-1 p-2' onClick={() => onClickUpdate(item.id)}>
                        <MDBIcon icon='pencil-alt' size='lg' className='text-primary'/>
                    </MDBBtn>
                    <MDBBtn flat rounded className='my-0 mx-1 p-2' onClick={() => onClickCopy(item.request.id)}>
                        <MDBIcon icon='copy' size='lg' far className='text-success'/>
                    </MDBBtn>
                </div>
            };
            data.push(row);
        });
        setDataTable(data);
    }

    const renderDataTable = useMemo(() => {
        if (documentsSelector.isLoading) {
            return <MDBCardBody className="d-flex justify-content-center"><MDBSpinner small/></MDBCardBody>;
        }

        return (
            <MDBDataTable
                className='document-table'
                responsive
                hover
                searching={false}
                sortable={false}
                noBottomColumns={true}
                paging={false}
                proSelect
                displayEntries={false}
                info={false}
                noRecordsFoundLabel="Không tìm thấy chứng thư nào"
                data={{columns: columns, rows: dataTable}}
            />
        )
    }, [dataTable, documentsSelector.isLoading]);

    return (
        <div id='document-container'>
            <MDBContainer fluid>
                <div className='text-center'>
                    <h1 className='page-title'>Quản lý chứng thư</h1>
                    <MDBBtn flat rounded className='m-0' onClick={() => setQueryParams({}, 'push')}>
                        <MDBIcon icon='undo' size='lg'/>
                    </MDBBtn>
                </div>
                <MDBRow className='pb-3'>
                    <MDBCol md='3'>
                        <MDBInput
                            label={formatMessage({id: 'APP.LABEL.SEARCH'})}
                            onChange={(e: any) => changeQueryParams('keyword', e.target.value)}
                            containerClass='mt-0'
                        />
                    </MDBCol>

                    <MDBCol md='2'>
                        <MDBDatePicker
                            clearable
                            format='DD-MM-YYYY'
                            autoOk
                            getValue={(e) => {
                                changeQueryParams('fromDate', e ? formatDateForApi(e) : null)
                            }}
                            className='mt-0 pt-2'
                            style={{with: '100%'}}
                            valueDefault={null as any}
                            emptyLabel='Từ'
                        />
                    </MDBCol>

                    <MDBCol md='2'>
                        <MDBDatePicker
                            clearable
                            format='DD-MM-YYYY'
                            autoOk
                            getValue={(e) => changeQueryParams('toDate', e ? formatDateForApi(e) : null)}
                            className='mt-0 pt-2'
                            style={{with: '100%'}}
                            valueDefault={null as any}
                            emptyLabel='Đến'
                        />
                    </MDBCol>

                    <MDBCol md='12'>
                        <MDBCard>
                            <MDBCardBody>
                                {renderDataTable}
                                <Pagination
                                    activeIndex={documents.pageId + 1}
                                    itemCount={documents.itemCount}
                                    pageSize={documents.itemsPerPage}
                                    onPageChanged={onPageChanged}
                                />
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <div id='document-qr-code' style={{width: 256, height: 310, display: 'none'}}>
                    <QRCode
                        id='qrCode'
                        value={`${window.location.origin.toString()}/public/document/${formattedCode}`}
                        imageSettings={{src: QRLogo, width: 115, height: 115}}
                        size={256}
                        level='H'
                    />
                    <p className="document-code text-center">{selectedCode}</p>
                </div>
            </MDBContainer>
        </div>
    );
}

export default DocumentContainer;
