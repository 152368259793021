export const GET_LIST_DOCUMENTS_REQUEST = 'GET_LIST_DOCUMENTS_REQUEST';
export const GET_LIST_DOCUMENTS_SUCCESS = 'GET_LIST_DOCUMENTS_SUCCESS';
export const GET_LIST_DOCUMENTS_FAILURE = 'GET_LIST_DOCUMENTS_FAILURE';

export const GET_DOCUMENT_DETAILS_REQUEST = 'GET_DOCUMENT_DETAILS_REQUEST';
export const GET_DOCUMENT_DETAILS_SUCCESS = 'GET_DOCUMENT_DETAILS_SUCCESS';
export const GET_DOCUMENT_DETAILS_FAILURE = 'GET_DOCUMENT_DETAILS_FAILURE';

export const CREATE_DOCUMENT_REQUEST = 'CREATE_DOCUMENT_REQUEST';
export const CREATE_DOCUMENT_SUCCESS = 'CREATE_DOCUMENT_SUCCESS';
export const CREATE_DOCUMENT_FAILURE = 'CREATE_DOCUMENT_FAILURE';

export const UPDATE_DOCUMENT_REQUEST = 'UPDATE_DOCUMENT_REQUEST';
export const UPDATE_DOCUMENT_SUCCESS = 'UPDATE_DOCUMENT_SUCCESS';
export const UPDATE_DOCUMENT_FAILURE = 'UPDATE_DOCUMENT_FAILURE';