import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import {
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBContainer,
    MDBDataTable,
    MDBDatePicker,
    MDBIcon,
    MDBInput,
    MDBRow,
    MDBSpinner,
} from 'mdbreact';
import { AppURI, DATE_FORMAT, RequestStatus } from 'src/core/utils/constants';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { formatDateForApi } from 'src/core/utils/common';
import Pagination from 'src/shared/components/pagination/pagination.component';
import RequestModel from 'src/core/models/request.model';
import { actGetListRequests } from 'src/core/redux/request/request.action';
import 'src/features/pages/request/request.style.scss';
import { generatePath } from 'react-router';
import { NumberParam, StringParam, useQueryParam, useQueryParams } from 'use-query-params';

type DataTable = {
    companyName: string;
    receivedDate: JSX.Element;
    taxCode: JSX.Element;
    requestCode: JSX.Element;
    status: JSX.Element;
    [rest: string]: any;
}

const columns = [
    {
        label: 'Khách hàng yêu cầu',
        field: 'companyName',
    },
    {
        label: 'Ngày nhận',
        field: 'receivedDate',
        attributes: {
            className: 'text-center'
        }
    },
    {
        label: 'Mã số thuế',
        field: 'taxCode',
        attributes: {
            className: 'text-center'
        }
    },
    {
        label: 'Mã yêu cầu',
        field: 'requestCode',
        attributes: {
            className: 'text-center'
        }
    },
    {
        label: 'Trạng thái',
        field: 'status',
        attributes: {
            className: 'text-center'
        }
    },
    {
        label: 'Chi tiết',
        field: 'commands',
    },
]

const RequestContainer = (): JSX.Element => {
    const {formatMessage} = useIntl();
    const history = useHistory();

    const dispatch = useDispatch();
    const requests = useSelector((state: any) => state.requestReducer.requests.data);
    const isLoading = useSelector((state: any) => state.requestReducer.requests.isLoading);
    const [dataTable, setDataTable] = useState<DataTable[]>([]);
    const [queryParams, setQueryParams] = useQueryParams({
        keyword: StringParam,
        fromDate: StringParam,
        toDate: StringParam,
    });
    const [page, setPage] = useQueryParam('page', NumberParam);

    useEffect(() => {
        onPageChanged(page || 1);
    }, [queryParams]);

    useEffect(() => {
        renderDataRow(requests.document);
    }, [requests.document]);

    const onClickCreateRequest = () => {
        history.push({
            pathname: AppURI.CREATE_REQUEST,
        });
    }

    const onPageChanged = (activePage: number) => {
        setPage(activePage);
        const params = {
            pageId: activePage - 1,
        }
        dispatch(actGetListRequests(params, queryParams));
    }

    const changeQueryParams = (name: string, value: string | null) => {
        setQueryParams({
            ...queryParams,
            [name]: value ? value : undefined,
        });
        setPage(1);
    };

    const navigateToDetails = (requestId: number) => {
        history.push({
            pathname: generatePath(AppURI.REQUEST_DETAILS, {requestId})
        });
    }

    const onClickUpdateRequest = (requestId: number) => {
        history.push({
            pathname: AppURI.UPDATE_REQUEST,
            state: {
                requestId
            }
        });
    }

    const renderDataRow = (requestList: RequestModel[]) => {
        const data: DataTable[] = [];
        requestList?.forEach((item: RequestModel, index: number) => {
            const row = {
                companyName: item.company.name,
                receivedDate: <div className='text-center'>{item.receivedDate ? moment(item.receivedDate).format(DATE_FORMAT) : ''}</div>,
                taxCode: <div className='text-center'>{item.company.taxCode}</div>,
                requestCode: <div className='text-center'>{item.code}</div>,
                status: <div className='text-center'>{RequestStatus[item.status]}</div>,
                commands: <div>
                    <MDBBtn flat rounded className='my-0 mx-1 p-2' onClick={() => navigateToDetails(item.id)}>
                        <MDBIcon icon='list' size='lg' className='text-primary'/>
                    </MDBBtn>
                    <MDBBtn flat rounded className='my-0 mx-1 p-2' onClick={() => onClickUpdateRequest(item.id)}>
                        <MDBIcon icon='pencil-alt' size='lg' className='text-primary'/>
                    </MDBBtn>
                </div>
            };
            data.push(row);
        });
        setDataTable(data);
    }

    const renderDataTable = useMemo(() => {
        if (isLoading) {
            return <MDBCardBody className="d-flex justify-content-center"><MDBSpinner small/></MDBCardBody>;
        }

        return (
            <MDBDataTable
                className='request-table'
                responsive
                hover
                searching={false}
                sortable={false}
                noBottomColumns={true}
                paging={false}
                proSelect
                displayEntries={false}
                info={false}
                noRecordsFoundLabel="Không tìm thấy yêu cầu nào"
                data={{columns: columns, rows: dataTable}}
            />
        )
    }, [dataTable, isLoading]);

    return (
        <MDBContainer fluid>
            <div className='text-center'>
                <h1 className='page-title'>Quản lý yêu cầu dịch vụ</h1>
                <MDBBtn flat rounded className='m-0' onClick={() => setQueryParams({}, 'push')}>
                    <MDBIcon icon='undo' size='lg'/>
                </MDBBtn>
            </div>
            <MDBRow className='pb-3'>
                <MDBCol md='3'>
                    <MDBInput
                        label={formatMessage({id: 'APP.LABEL.SEARCH'})}
                        onChange={(e: any) => changeQueryParams('keyword', e.target.value)}
                        containerClass='mt-0'
                    />
                </MDBCol>

                <MDBCol md='2'>
                    <MDBDatePicker
                        clearable
                        format='DD-MM-YYYY'
                        autoOk
                        getValue={(e) => {
                            changeQueryParams('fromDate', e ? formatDateForApi(e) : null)
                        }}
                        className='mt-0 pt-2'
                        style={{with: '100%'}}
                        valueDefault={null as any}
                        emptyLabel='Từ'
                    />
                </MDBCol>

                <MDBCol md='2'>
                    <MDBDatePicker
                        clearable
                        format='DD-MM-YYYY'
                        autoOk
                        getValue={(e) => changeQueryParams('toDate', e ? formatDateForApi(e) : null)}
                        className='mt-0 pt-2'
                        style={{with: '100%'}}
                        valueDefault={null as any}
                        emptyLabel='Đến'
                    />
                </MDBCol>

                <MDBCol md='5' className='text-right'>
                    <MDBBtn
                        color="success"
                        onClick={onClickCreateRequest}>Tạo yêu cầu mới</MDBBtn>
                </MDBCol>

                <MDBCol md='12'>
                    <MDBCard>
                        <MDBCardBody>
                            {renderDataTable}
                            <Pagination
                                activeIndex={requests.pageId + 1}
                                itemCount={requests.itemCount}
                                pageSize={requests.itemsPerPage}
                                onPageChanged={onPageChanged}
                            />
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>
    );
}

export default RequestContainer;
