import React, { useMemo, useState } from 'react'
import { MDBPagination, MDBPageItem, MDBPageNav } from 'mdbreact';
import * as CONST from 'src/core/utils/constants';
import _ from 'lodash';

type Props = {
    pageSize: number;
    itemCount: number;
    onPageChanged: (pageIndex: number) => void;
    activeIndex: number;
}

const Pagination = (props: Props): JSX.Element => {

    const [pageIndex, setPageIndex] = useState(1);

    const totalPage = Math.ceil(props.itemCount / (props.pageSize || CONST.DEFAULT_PAGE_SIZE));

    const onPageChange = (pageIndex: number) => {
        if (props.activeIndex === pageIndex) return;

        props.onPageChanged(pageIndex);
        setPageIndex(pageIndex);
    }

    const onPreviousPage = () => {
        if (pageIndex === 1) return;

        setPageIndex(page => {
            props.onPageChanged(page - 1);
            return page - 1;
        });
    }

    const onNextPage = () => {
        if (pageIndex >= totalPage) return;
        setPageIndex(page => {
            props.onPageChanged(page + 1);
            return page + 1;
        });
    }

    const onFirstPage = () => {
        setPageIndex(page => {
            props.onPageChanged(1);
            return 1;
        });
    }

    const onLastPage = () => {
        setPageIndex(page => {
            props.onPageChanged(totalPage);
            return totalPage;
        });
    }

    const renderNumberPage = useMemo(() => {
        if (_.isNil(props.itemCount) || props.itemCount === 0) return <></>;

        const pages: JSX.Element[] = [];
        const minPage = props.activeIndex - 2 > 0 ? props.activeIndex - 2 : 1;
        const maxPage = props.activeIndex + 2 < totalPage ? props.activeIndex + 2 : totalPage;
        for (let index = minPage; index <= maxPage; index++) {
            pages.push(
                <MDBPageItem style={{ zIndex: 1 }} key={index} active={props.activeIndex === index} onClick={() => onPageChange(index)}>
                    <MDBPageNav>
                        {index}
                    </MDBPageNav>
                </MDBPageItem>
            )
        }

        return pages;
    }, [props.activeIndex, props.itemCount, pageIndex])

    return (
        <MDBPagination color="blue">
            <MDBPageItem disabled={pageIndex === 1} onClick={onFirstPage}>
                <MDBPageNav>
                    <span>First</span>
                </MDBPageNav>
            </MDBPageItem>
            <MDBPageItem disabled={pageIndex === 1} onClick={onPreviousPage}>
                <MDBPageNav>
                    &laquo;
                </MDBPageNav>
            </MDBPageItem>
            {renderNumberPage}
            <MDBPageItem disabled={pageIndex >= totalPage} onClick={onNextPage}>
                <MDBPageNav>
                    &raquo;
                </MDBPageNav>
            </MDBPageItem>
            <MDBPageItem disabled={pageIndex >= totalPage} onClick={onLastPage}>
                <MDBPageNav>
                    <span>Last</span>
                </MDBPageNav>
            </MDBPageItem>
        </MDBPagination>
    );
}

export default Pagination;
