import CreateCompanyRequest from 'src/core/models/requests/create-company-request.model';

export default class CreateRequestRequest {
    receivedDate: string;
    requestTypeId: number;
    inspectionFee: number;
    transportVehicle: string;
    companyId?: number;
    company?: CreateCompanyRequest;

    constructor(receivedDate: string, requestTypeId: number, inspectionFee: number, transportVehicle: string) {
        this.receivedDate = receivedDate;
        this.requestTypeId = requestTypeId;
        this.inspectionFee = inspectionFee;
        this.transportVehicle = transportVehicle;
    }
}
