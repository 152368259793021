import CreateCompanyRequest from 'src/core/models/requests/create-company-request.model';

export default class UpdateRequestRequest {
    receivedDate: string;
    inspectionFee: number;
    transportVehicle: string;
    companyId?: number;
    company?: CreateCompanyRequest;

    constructor(receivedDate: string, inspectionFee: number, transportVehicle: string) {
        this.receivedDate = receivedDate;
        this.inspectionFee = inspectionFee;
        this.transportVehicle = transportVehicle;
    }
}
