import React, { useEffect, useMemo, useState } from 'react';
import { MDBBtn, MDBCol, MDBContainer, MDBDataTable, MDBIcon, MDBRow, MDBSpinner } from 'mdbreact';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actGetRequestDetails } from 'src/core/redux/request/request.action';
import { AppURI, DATE_FORMAT } from 'src/core/utils/constants';
import moment from 'moment';
import DocumentModel from 'src/core/models/document.model';
import { downloadQR } from 'src/core/utils/common';
import RequestInformation from 'src/features/components/request/request-information/request-information.component';
import 'src/features/pages/request-details/request-details.style.scss';
import QRLogo from 'src/shared/assets/img/qr_logo.png';

const QRCode = require('qrcode.react');

type DataTable = {
    no: number;
    code: string;
    date: string;
    qr: JSX.Element;
    file: JSX.Element;
    [rest: string]: any;
}

const columns = [
    {
        label: 'No.',
        field: 'no',
    },
    {
        label: 'Số chứng thư',
        field: 'code',
    },
    {
        label: 'Ngày chứng thư',
        field: 'date',
    },
    {
        label: 'QR',
        field: 'qr',
    },
    {
        label: 'File',
        field: 'file',
    },
    {
        label: 'Chỉnh sửa',
        field: 'commands',
    },
]

const RequestDetailsContainer = (): JSX.Element => {
    const {requestId} = useParams<{ requestId: string }>();
    const history = useHistory();
    const dispatch = useDispatch();
    const requestDetailsSelector = useSelector((state: any) => state.requestReducer.requestDetails);
    const requestDetails = requestDetailsSelector.data;
    const [dataTable, setDataTable] = useState<DataTable[]>([]);
    const [selectedCode, setSelectedCode] = useState('');

    useEffect(() => {
        if (requestId) {
            dispatch(actGetRequestDetails(Number(requestId)));
        }
    }, []);

    useEffect(() => {
        requestDetails && renderDataRow(requestDetails.documents);
    }, [requestDetails]);

    const formattedCode = useMemo(() => {
        return (selectedCode.replaceAll('/', '_'));
    }, [selectedCode]);

    const onClickDownloadQR = async (code: string) => {
        await setSelectedCode(code);
        const reformatCode = code.replaceAll('/', '_');
        downloadQR(reformatCode);
    }

    const onClickUpdateDocument = (documentId: number) => {
        history.push({
            pathname: AppURI.UPDATE_DOCUMENT,
            state: {
                documentId
            }
        })
    }

    const renderDataRow = (documentList: DocumentModel[]) => {
        const data: DataTable[] = [];
        documentList?.forEach((item: DocumentModel, index: number) => {
            let fileElement = <div/>;
            if (item.file) {
                const fileName = decodeURIComponent(item.file.split('/').slice(-1).pop() || '');
                fileElement = <a href={item.file} target="_blank" rel="noreferrer" className='btn-link text-primary'>{fileName}</a>
            }

            const row = {
                no: index + 1,
                code: item.code,
                date: item.date ? moment(item.date).format(DATE_FORMAT) : '',
                qr: <MDBBtn flat rounded className='my-0 mx-1 p-2' onClick={() => onClickDownloadQR(item.code)}>
                    <MDBIcon icon='download' size='lg' className='text-primary'/>
                </MDBBtn>,
                file: fileElement,
                commands: <div>
                    <MDBBtn flat rounded className='my-0 mx-1 p-2' onClick={() => onClickUpdateDocument(item.id)}>
                        <MDBIcon icon='pencil-alt' size='lg' className='text-primary'/>
                    </MDBBtn>
                </div>
            };
            data.push(row);
        });
        setDataTable(data);
    }

    const renderDataTable = useMemo(() => {
        return (
            <MDBDataTable
                className='document-table'
                responsive
                hover
                searching={false}
                sortable={false}
                noBottomColumns={true}
                paging={false}
                proSelect
                displayEntries={false}
                info={false}
                noRecordsFoundLabel="Không có chứng thư nào"
                data={{columns: columns, rows: dataTable}}
            />
        )
    }, [dataTable]);

    return (
        <div id='request-details'>
            <MDBContainer className='pb-4'>
                <div className='text-center'>
                    <h1 className='page-title'>Chi tiết yêu cầu</h1>
                </div>
                {!requestDetails || requestDetailsSelector.isLoading ?
                    <div className='m-5 text-center'>
                        <MDBSpinner/>
                    </div>
                    :
                    <MDBRow>
                        <MDBCol size='12'>
                            <RequestInformation data={requestDetails}/>
                        </MDBCol>
                        <MDBCol size='12'>
                            <div className="font-weight-normal mt-4">DANH SÁCH CHỨNG THƯ</div>
                            {renderDataTable}
                            <div className='text-center mt-3'>
                                <MDBBtn color='success' onClick={() => history.push(AppURI.CREATE_DOCUMENT)}>
                                    Tạo chứng thư mới
                                </MDBBtn>
                            </div>
                        </MDBCol>
                    </MDBRow>
                }
                <div id='document-qr-code' style={{width: 256, height: 310, display: 'none'}}>
                    <QRCode
                        id='qrCode'
                        value={`${window.location.origin.toString()}/public/document/${formattedCode}`}
                        imageSettings={{src: QRLogo, width: 115, height: 115}}
                        size={256}
                        level='H'
                    />
                    <p className="document-code text-center">{selectedCode}</p>
                </div>
            </MDBContainer>
        </div>
    );
}


export default RequestDetailsContainer;
