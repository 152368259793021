import React, { useEffect, useState } from 'react';
import * as CONST from 'src/core/utils/constants';
import * as COMMON from 'src/core/utils/common';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import LoginInfo from 'src/core/models/login-info.model';
import UserInfo from 'src/core/models/user-info.model';

const RouteGuard = ({ Component, ...props }: any): any => {
    const [isAuthenticated, setAuthenticated] = useState(false);
    const history = useHistory();

    useEffect(() => {
        checkAuthen();
    }, []);

    const checkAuthen = () => {
        const loginInfo = COMMON.getLocalStorage<LoginInfo>(CONST.LocalStorage.LOGIN_INFO);

        if (_.isEmpty(loginInfo)) {
            setAuthenticated(false);
            history.push(CONST.AppURI.LOGIN);
            return;
        }

        const userInfo = COMMON.getLocalStorage<UserInfo>(CONST.LocalStorage.LOGIN_INFO);
        const authorities = userInfo.authorities;
        // TODO

        setAuthenticated(true);
    }

    return (
        isAuthenticated &&
        <Component
            {...props}
        />
    )
}

export default RouteGuard;
