import React, { ReactNode } from 'react';
import _ from 'lodash';
import RouteType from 'src/core/models/route.model';
import RouteWithLayout from 'src/shared/hoc/route-with-layout.hoc';
import moment from 'moment';
import html2canvas from 'html2canvas';

const FileSaver = require('file-saver');

export const buildRoutes = (routes: Array<RouteType>): ReactNode[] => {
    const elementRoute: ReactNode[] = [];
    if (_.isEmpty(routes)) {
        return elementRoute;
    }

    const length = routes.length;
    for (let index = 0; index < length; index++) {
        const route = routes[index];

        elementRoute.push(
            <RouteWithLayout
                key={route.name + index}
                exact={route.exact}
                path={route.path}
                component={route.main}
                withNav={route.withNav}
            />
        )
    }

    return elementRoute;
}

/**
 * Converts a string to its html characters completely.
 *
 * @param {String} str String with unescaped HTML characters
 **/
export const encode = (str: any): string => {
    const buf = [];

    for (let i = str.length - 1; i >= 0; i--) {
        buf.unshift(['&#', str[i].charCodeAt(), ';'].join(''));
    }

    return buf.join('');
};

/**
 * Converts an html characterSet into its original character.
 *
 * @param {String} str htmlSet entities
 **/
export const decode = (str: string): string => {
    return str.replace(/&#(\d+);/g, function (match, dec) {
        return String.fromCharCode(dec);
    });
};

export const isNaNDate = (value: Date): boolean => {
    if (_.isNil(value) || _.isNaN(value.getDate()) || _.isNaN(value.getMonth()) || _.isNaN(value.getFullYear())) {
        return true;
    }

    return false;
}

export const currencyFormatter = (value: number, locale = 'vi', currency = 'VND', decimalDigits = 0): string => {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'currency',
        currency,
        minimumFractionDigits: decimalDigits,
        // the default value for minimumFractionDigits depends on the currency
        // and is usually already 2
    });

    return _.isFinite(value) ? formatter.format(value) : '';
};

export const formatNumber = (num: number) => {
    if (!num) {
        return '0';
    }
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const roundUp = (num: number, precision: number): number | string => {
    if (!_.isFinite(num)) return '';

    precision = Math.pow(10, precision);
    return Math.ceil(num * precision) / precision;
};

export const roundDown = (num: number, precision: number): number | string => {
    if (!_.isFinite(num)) return '';

    precision = Math.pow(10, precision);
    return Math.floor(num * precision) / precision;
};

export function setLocalStorage(key: string, data = {}): void {
    localStorage.setItem(key, JSON.stringify(data || {}));
}

export function getLocalStorage<T = any>(key: string): T {
    try {
        return JSON.parse(localStorage.getItem(key) || '{}');
    } catch (error) {
        return {} as any;
    }
}

export function removeLocalStorage(key: string): void {
    localStorage.removeItem(key);
}

export function clearLocalStorage(): void {
    localStorage.clear();
}

export function setSessionStorage(key: string, data = {}): void {
    sessionStorage.setItem(key, JSON.stringify(data || {}));
}

export function getSessionStorage<T = any>(key: string): T {
    try {
        return JSON.parse(sessionStorage.getItem(key) || '{}');
    } catch (error) {
        return {} as any;
    }
}

export function removeSessionStorage(key: string): void {
    sessionStorage.removeItem(key);
}

export function clearSessionStorage(): void {
    sessionStorage.clear();
}

export const formatDateForApi = (date: Date): string => {
    return moment(date).format('YYYY-MM-DD');
}

export const getTextFromHtml = (html: string) => {
    return html.replace(/<[^>]*>?/gm, '');
}

export const getRandomKey = () => `${Math.floor((Math.random() * 1000))}`;

export const downloadQR = (code: string) => {
    const container: any = document.getElementById('document-qr-code');
    html2canvas(container, {
        onclone: (clonedDoc: any) => {
            clonedDoc.getElementById('document-qr-code').style.display = 'block';
        }
    }).then((downloadCanvas) => {
        const pngUrl = downloadCanvas.toDataURL('image/png')
            .replace('image/png', 'image/octet-stream');
        const downloadLink = document.createElement('a');
        downloadLink.href = pngUrl;
        downloadLink.download = `${code}.png`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    });
}

export const downloadFile = (url: string, fileName: string) => {
    const extension = fileName.split('.').pop();
    if (extension?.toLowerCase() === 'pdf') {
        downloadPDF(url, fileName);
        return;
    }
    FileSaver.saveAs(url, fileName);
}

export const downloadPDF = (url: string, fileName: string) => {
    const oReq = new XMLHttpRequest();
    oReq.open('GET', url, true);
    oReq.responseType = 'blob';
    oReq.onload = function() {
        const file = new Blob([oReq.response], {
            type: 'application/pdf'
        });
        FileSaver.saveAs(file, fileName);
    };
    oReq.send();
}