export const GET_LIST_REQUESTS_REQUEST = 'GET_LIST_REQUESTS_REQUEST';
export const GET_LIST_REQUESTS_SUCCESS = 'GET_LIST_REQUESTS_SUCCESS';
export const GET_LIST_REQUESTS_FAILURE = 'GET_LIST_REQUESTS_FAILURE';

export const CREATE_REQUEST_REQUEST = 'CREATE_REQUEST_REQUEST';
export const CREATE_REQUEST_SUCCESS = 'CREATE_REQUEST_SUCCESS';
export const CREATE_REQUEST_FAILURE = 'CREATE_REQUEST_FAILURE';

export const GET_REQUEST_DETAILS_REQUEST = 'GET_REQUEST_DETAILS_REQUEST';
export const GET_REQUEST_DETAILS_SUCCESS = 'GET_REQUEST_DETAILS_SUCCESS';
export const GET_REQUEST_DETAILS_FAILURE = 'GET_REQUEST_DETAILS_FAILURE';

export const UPDATE_REQUEST_REQUEST = 'UPDATE_REQUEST_REQUEST';
export const UPDATE_REQUEST_SUCCESS = 'UPDATE_REQUEST_SUCCESS';
export const UPDATE_REQUEST_FAILURE = 'UPDATE_REQUEST_FAILURE';

export const DELETE_DOCUMENT_OF_REQUEST_REQUEST = 'DELETE_DOCUMENT_OF_REQUEST_REQUEST';
export const DELETE_DOCUMENT_OF_REQUEST_SUCCESS = 'DELETE_DOCUMENT_OF_REQUEST_SUCCESS';
export const DELETE_DOCUMENT_OF_REQUEST_FAILURE = 'DELETE_DOCUMENT_OF_REQUEST_FAILURE';

export const DELETE_REQUEST_REQUEST = 'DELETE_REQUEST_REQUEST';
export const DELETE_REQUEST_SUCCESS = 'DELETE_REQUEST_SUCCESS';
export const DELETE_REQUEST_FAILURE = 'DELETE_REQUEST_FAILURE';