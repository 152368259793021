import * as Types from 'src/core/redux/document/document.type';
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import { AxiosResponse } from 'axios';
import BaseResponse from 'src/core/models/responses/base-response.model';
import CreateDocumentRequest from 'src/core/models/requests/create-document-request.model';
import PagingRequest from 'src/core/models/paging-request.model';
import UpdateDocumentRequest from 'src/core/models/requests/update-document-request.model';

const requestRepository = Repositories[RepositoryName.REQUEST];

export const actGetListDocuments = (paging: PagingRequest, queryParams = {}) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_DOCUMENTS_REQUEST, Types.GET_LIST_DOCUMENTS_SUCCESS, Types.GET_LIST_DOCUMENTS_FAILURE],
        callAPI: () => requestRepository.getListDocuments(paging, queryParams),
    });
};

export const actGetDocumentDetails = (id: number) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_DOCUMENT_DETAILS_REQUEST, Types.GET_DOCUMENT_DETAILS_SUCCESS, Types.GET_DOCUMENT_DETAILS_FAILURE],
        callAPI: () => requestRepository.getDocumentDetails(id),
    });
};


export const actCreateDocument = (request: CreateDocumentRequest, callback: (documentCode: string) => void) => (dispatch: any): void => {
    dispatch({
        types: [Types.CREATE_DOCUMENT_REQUEST, Types.CREATE_DOCUMENT_SUCCESS, Types.CREATE_DOCUMENT_FAILURE],
        callAPI: () => requestRepository.createDocument(request),
        callBack: {
            success: (res: AxiosResponse<BaseResponse>) => callback(res.data.payload)
        }
    });
};

export const actUpdateDocument = (id: number, request: UpdateDocumentRequest) => (dispatch: any): void => {
    dispatch({
        types: [Types.UPDATE_DOCUMENT_REQUEST, Types.UPDATE_DOCUMENT_SUCCESS, Types.UPDATE_DOCUMENT_FAILURE],
        callAPI: () => requestRepository.updateDocument(id, request),
    });
};
