import { AnyAction } from 'redux';
import * as Types from 'src/core/redux/request-type/request-type.type';
import { createReducer } from 'src/core/utils/redux';
import RequestTypeState from 'src/core/models/redux/request-type-state.model';

const initialState: RequestTypeState = {
    requestTypes: {
        data: [],
        error: null,
        isLoading: false,
    },
};

const requestTypeReducer = createReducer(initialState, {
    [Types.GET_LIST_REQUEST_TYPES_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            requestTypes: {
                ...state.requestTypes,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_REQUEST_TYPES_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            requestTypes: {
                data: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.GET_LIST_REQUEST_TYPES_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            requestTypes: {
                ...state.requestTypes,
                error: action.payload,
                isLoading: false
            }
        };
    },
})

export default requestTypeReducer;
