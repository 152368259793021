import { combineReducers } from 'redux';
import commonReducer from 'src/core/redux/common/common.reducer';
import userReducer from 'src/core/redux/user/user.reducer';
import cloudStorageReducer from 'src/core/redux/cloud-storage/cloud-storage.reducer';
import requestReducer from 'src/core/redux/request/request.reducer';
import requestTypeReducer from 'src/core/redux/request-type/request-type.reducer';
import companyReducer from 'src/core/redux/company/company.reducer';
import documentReducer from 'src/core/redux/document/document.reducer';

const appReducers = combineReducers({
    cloudStorageReducer,
    commonReducer,
    userReducer,
    requestReducer,
    requestTypeReducer,
    companyReducer,
    documentReducer,
});

export default appReducers;
