export default class CreateCompanyRequest {
    name: string;
    taxCode: string;
    address: string;

    constructor(name: string, taxCode: string, address: string) {
        this.name = name;
        this.taxCode = taxCode;
        this.address = address;
    }
}
