import React from 'react';
import RequestModel from 'src/core/models/request.model';
import { MDBCol, MDBRow } from 'mdbreact';
import moment from 'moment';
import { DATE_FORMAT, RequestStatus } from 'src/core/utils/constants';
import { formatNumber } from 'src/core/utils/common';

type Props = {
    data: RequestModel;
}

const RequestInformation = (props: Props): JSX.Element => {
    const request = props.data;

    return (
        <div>
            <div className="font-weight-normal mt-4">THÔNG TIN CÔNG TY</div>
            <MDBRow className='mt-2'>
                <MDBCol md="2">
                    <div className='font-weight-normal'>Tên công ty</div>
                </MDBCol>
                <MDBCol md="10">
                    <div>{request.company.name}</div>
                </MDBCol>
                <MDBCol md="2">
                    <div className='font-weight-normal'>Mã số thuế</div>
                </MDBCol>
                <MDBCol md="10">
                    <div>{request.company.taxCode}</div>
                </MDBCol>
                <MDBCol md="2">
                    <div className='font-weight-normal'>Địa chỉ</div>
                </MDBCol>
                <MDBCol md="10">
                    <div>{request.company.address}</div>
                </MDBCol>
            </MDBRow>
            <div className="font-weight-normal mt-4">THÔNG TIN YÊU CẦU</div>
            <MDBRow className='mt-2'>
                <MDBCol md="2">
                    <div className='font-weight-normal'>Mã yêu cầu</div>
                </MDBCol>
                <MDBCol md="10">
                    <div className='font-weight-bold text-primary'>{request.code}</div>
                </MDBCol>
                <MDBCol md="2">
                    <div className='font-weight-normal'>Ngày nhận</div>
                </MDBCol>
                <MDBCol md="10">
                    <div>{moment(request.receivedDate).format(DATE_FORMAT)}</div>
                </MDBCol>
                <MDBCol md="2">
                    <div className='font-weight-normal'>Loại hình</div>
                </MDBCol>
                <MDBCol md="10">
                    <div>{request.requestType.name}</div>
                </MDBCol>
                <MDBCol md="2">
                    <div className='font-weight-normal'>Phí giám định</div>
                </MDBCol>
                <MDBCol md="10">
                    <div>{formatNumber(request.inspectionFee)} VND</div>
                </MDBCol>
                <MDBCol md="2">
                    <div className='font-weight-normal'>Phương tiện vận tải</div>
                </MDBCol>
                <MDBCol md="10">
                    <div>{request.transportVehicle}</div>
                </MDBCol>
                <MDBCol md="2">
                    <div className='font-weight-normal'>Trạng thái</div>
                </MDBCol>
                <MDBCol md="10">
                    <div>{RequestStatus[request.status]}</div>
                </MDBCol>
            </MDBRow>
        </div>
    );
}

export default RequestInformation;
