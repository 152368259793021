import React from 'react';
import Login from 'src/features/pages/login/login.container';
import NotFoundComponent from 'src/shared/components/not-found/not-found.component';
import * as CONST from 'src/core/utils/constants';
import RouteGuard from 'src/shared/hoc/route-guard.hoc';
import HomeContainer from 'src/features/pages/home/home.container';
import RequestContainer from 'src/features/pages/request/request.container';
import CreateRequestContainer from 'src/features/pages/create-request/create-request.container';
import RequestDetailsContainer from 'src/features/pages/request-details/request-details.container';
import CreateDocumentContainer from 'src/features/pages/create-document/create-document.container';
import DocumentContainer from 'src/features/pages/document/document.container';
import UpdateDocumentContainer from 'src/features/pages/update-document/update-document.container';
import DocumentDetailsContainer from 'src/features/pages/document-details/document-details.container';
import PublicDocumentContainer from 'src/features/pages/public-document/public-document.container';
import UpdateRequestContainer from 'src/features/pages/update-request/update-request.container';

export const mainRoutes = [
    {
        path: CONST.AppURI.HOME,
        exact: true,
        name: 'Home',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: HomeContainer, ...props })
    },
    {
        path: CONST.AppURI.REQUESTS,
        exact: true,
        name: 'Requests',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: RequestContainer, ...props })
    },
    {
        path: CONST.AppURI.CREATE_REQUEST,
        exact: true,
        name: 'Create Request',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: CreateRequestContainer, ...props })
    },
    {
        path: CONST.AppURI.REQUEST_DETAILS,
        exact: true,
        name: 'Request Details',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: RequestDetailsContainer, ...props })
    },
    {
        path: CONST.AppURI.UPDATE_REQUEST,
        exact: true,
        name: 'Update Request',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: UpdateRequestContainer, ...props })
    },
    {
        path: CONST.AppURI.DOCUMENTS,
        exact: true,
        name: 'Documents',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: DocumentContainer, ...props })
    },
    {
        path: CONST.AppURI.CREATE_DOCUMENT,
        exact: true,
        name: 'Create Document',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: CreateDocumentContainer, ...props })
    },
    {
        path: CONST.AppURI.UPDATE_DOCUMENT,
        exact: true,
        name: 'Update Document',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: UpdateDocumentContainer, ...props })
    },
    {
        path: CONST.AppURI.DOCUMENT_DETAILS,
        exact: true,
        name: 'Document Details',
        main: (props: Record<string, unknown>): JSX.Element => RouteGuard({ Component: DocumentDetailsContainer, ...props })
    },
];

export const appRoutesWithoutNav = [
    {
        path: CONST.AppURI.LOGIN,
        exact: true,
        name: 'Login',
        main: (props: Record<string, unknown>): JSX.Element => <Login {...props} />,
        withNav: false
    },
    {
        path: CONST.AppURI.NOT_FOUND,
        exact: true,
        name: 'Not found',
        main: (props: Record<string, unknown>): JSX.Element => <NotFoundComponent {...props} />,
        withNav: false
    },
    {
        path: CONST.AppURI.PUBLIC_DOCUMENT,
        exact: true,
        name: 'Public document',
        main: (props: Record<string, unknown>): JSX.Element => <PublicDocumentContainer {...props} />,
        withNav: false
    },
];
