import { AnyAction } from 'redux';
import * as Types from 'src/core/redux/document/document.type';
import { createReducer } from 'src/core/utils/redux';
import DocumentState from 'src/core/models/redux/document-state.model';
import PagingResponse from 'src/core/models/paging/paging-response.model';
import DocumentModel from 'src/core/models/document.model';

const initialState: DocumentState = {
    documents: {
        data: new PagingResponse<DocumentModel>(),
        error: null,
        isLoading: false,
    },
    documentDetails: {
        data: null,
        error: null,
        isLoading: false,
    },
    createDocument: {
        response: null,
        error: null,
        isLoading: false,
    },
    updateDocument: {
        response: null,
        error: null,
        isLoading: false,
    },
};

const documentReducer = createReducer(initialState, {
    [Types.GET_LIST_DOCUMENTS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            documents: {
                ...state.documents,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_DOCUMENTS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            documents: {
                data: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.GET_LIST_DOCUMENTS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            documents: {
                ...state.documents,
                error: action.payload,
                isLoading: false
            }
        };
    },
    [Types.GET_DOCUMENT_DETAILS_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            documentDetails: {
                ...state.documentDetails,
                isLoading: true,
            }
        };
    },
    [Types.GET_DOCUMENT_DETAILS_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            documentDetails: {
                data: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.GET_DOCUMENT_DETAILS_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            documentDetails: {
                ...state.documentDetails,
                error: action.payload,
                isLoading: false
            }
        };
    },
    [Types.CREATE_DOCUMENT_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createDocument: {
                ...state.createDocument,
                isLoading: true,
            }
        };
    },
    [Types.CREATE_DOCUMENT_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createDocument: {
                response: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.CREATE_DOCUMENT_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            createDocument: {
                ...state.createDocument,
                error: action.payload,
                isLoading: false
            }
        };
    },
    [Types.UPDATE_DOCUMENT_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            updateDocument: {
                ...state.updateDocument,
                isLoading: true,
            }
        };
    },
    [Types.UPDATE_DOCUMENT_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            updateDocument: {
                response: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.UPDATE_DOCUMENT_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            updateDocument: {
                ...state.updateDocument,
                error: action.payload,
                isLoading: false
            }
        };
    },
})

export default documentReducer;
