import * as Types from 'src/core/redux/request/request.type';
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';
import PagingRequest from 'src/core/models/paging-request.model';
import CreateRequestRequest from 'src/core/models/requests/create-request-request.model';
import { AxiosResponse } from 'axios';
import BaseResponse from 'src/core/models/responses/base-response.model';
import UpdateRequestRequest from 'src/core/models/requests/update-request-request.model';

const requestRepository = Repositories[RepositoryName.REQUEST];

export const actGetListRequests = (paging: PagingRequest, queryParams = {}) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_REQUESTS_REQUEST, Types.GET_LIST_REQUESTS_SUCCESS, Types.GET_LIST_REQUESTS_FAILURE],
        callAPI: () => requestRepository.getListRequests(paging, queryParams),
    });
};

export const actCreateRequest = (request: CreateRequestRequest, callback: (requestCode: string) => void) => (dispatch: any): void => {
    dispatch({
        types: [Types.CREATE_REQUEST_REQUEST, Types.CREATE_REQUEST_SUCCESS, Types.CREATE_REQUEST_FAILURE],
        callAPI: () => requestRepository.createRequest(request),
        callBack: {
            success: (res: AxiosResponse<BaseResponse>) => callback(res.data.payload)
        }
    });
};

export const actGetRequestDetails = (id: number) => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_REQUEST_DETAILS_REQUEST, Types.GET_REQUEST_DETAILS_SUCCESS, Types.GET_REQUEST_DETAILS_FAILURE],
        callAPI: () => requestRepository.getRequestDetails(id),
    });
};

export const actUpdateRequest = (id: number, request: UpdateRequestRequest) => (dispatch: any): void => {
    dispatch({
        types: [Types.UPDATE_REQUEST_REQUEST, Types.UPDATE_REQUEST_SUCCESS, Types.UPDATE_REQUEST_FAILURE],
        callAPI: () => requestRepository.updateRequest(id, request)
    });
};

export const actDeleteDocumentOfRequest = (id: number) => (dispatch: any): void => {
    dispatch({
        type: Types.DELETE_DOCUMENT_OF_REQUEST_REQUEST
    });
    requestRepository.deleteDocument(id)
        .then((res) => {
            dispatch({
                type: Types.DELETE_DOCUMENT_OF_REQUEST_SUCCESS,
                payload: {
                    id,
                    response: res.data
                }
            });
        })
        .catch((error) => {
            alert('Có lỗi xảy ra. Vui lòng thử lại sau.');
            dispatch({
                type: Types.DELETE_DOCUMENT_OF_REQUEST_FAILURE,
                payload: error
            })
        });
};

export const actDeleteRequest = (id: number, callback = () => {}) => (dispatch: any): void => {
    dispatch({
        types: [Types.DELETE_REQUEST_REQUEST, Types.DELETE_REQUEST_REQUEST, Types.DELETE_REQUEST_FAILURE],
        callAPI: () => requestRepository.deleteRequest(id),
        callBack: {
            success: callback
        }
    });
};