import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { MDBBtn, MDBCol, MDBContainer, MDBDatePicker, MDBRow, MDBSpinner } from 'mdbreact';
import { useForm } from 'react-hook-form';
import ErrorText from 'src/shared/components/error-text/error-text.component';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateForApi } from 'src/core/utils/common';
import _ from 'lodash';
import { PUBLIC_BUCKET, PUBLIC_BUCKET_PREFIX } from 'src/core/utils/constants';
import { actUploadFile } from 'src/core/redux/cloud-storage/cloud-storage.action';
import { useLocation } from 'react-router-dom';
import { actGetDocumentDetails, actUpdateDocument } from 'src/core/redux/document/document.action';
import UpdateDocumentRequest from 'src/core/models/requests/update-document-request.model';
import RequestInformation from 'src/features/components/request/request-information/request-information.component';
import 'src/features/pages/update-document/update-document.style.scss';

const UpdateDocumentContainer = (): JSX.Element => {
    const location = useLocation();
    const locationState: any = location.state;
    const {formatMessage} = useIntl();
    const dispatch = useDispatch();
    const {register, unregister, handleSubmit, setValue, trigger, formState: {errors}} = useForm();
    const updateDocumentSelector = useSelector((state: any) => state.documentReducer.updateDocument);
    const documentDetailsSelector = useSelector((state: any) => state.documentReducer.documentDetails);
    const documentDetails = documentDetailsSelector.data;
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        registerForm();
        return () => {
            unregister();
        }
    }, []);

    useEffect(() => {
        if (locationState?.documentId) {
            dispatch(actGetDocumentDetails(locationState.documentId));
        }
    }, [locationState?.documentId]);

    const registerForm = () => {
        register('date', {
            required: formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: 'Ngày chứng thư'}),
        });
        register('file');
    }

    useEffect(() => {
        if (documentDetails) {
            setValue('date', documentDetails.date);
            if (documentDetails.file) {
                setValue('file', documentDetails.file);
                const fileName = documentDetails.file.split('/').slice(-1).pop();
                setFileName(decodeURIComponent(fileName))
            }
        }
    }, [documentDetails]);

    const handleChangeInput = async (name: string, value: any) => {
        setValue(name, value);
        await trigger(name);
    }

    const onSubmit = (data: any) => {
        const request = new UpdateDocumentRequest(data.date, data.file);
        dispatch(actUpdateDocument(documentDetails.id, request));
    }

    const uploadFile = (file: File) => {
        dispatch(actUploadFile(file, (objectName) => {
            handleChangeInput('file', PUBLIC_BUCKET_PREFIX + encodeURIComponent(objectName));
            setFileName(file.name);
        }, PUBLIC_BUCKET));
    }

    return (
        <MDBContainer>
            <h1 className='page-title'>Chỉnh sửa chứng thư</h1>
            {!documentDetails || documentDetailsSelector.isLoading ?
                <div className='m-5 text-center'>
                    <MDBSpinner/>
                </div>
                :
                <div>
                    <MDBRow>
                        <MDBCol size='12' className='mx-auto'>
                            <RequestInformation data={documentDetails.request}/>
                            <div className="font-weight-normal mt-4">THÔNG TIN CHỨNG THƯ</div>
                            <MDBRow className='mt-2'>
                                <MDBCol md="2">
                                    <div className="pt-2">Ngày chứng thư</div>
                                </MDBCol>
                                <MDBCol md="10">
                                    <MDBDatePicker
                                        clearable
                                        format='DD-MM-YYYY'
                                        autoOk
                                        getValue={(e) => handleChangeInput('date', e ? formatDateForApi(e) : null)}
                                        className='mt-0 mb-2'
                                        valueDefault={new Date(documentDetails.date)}
                                        emptyLabel='(chọn ngày)'
                                    />
                                    {errors.date && <ErrorText>{errors.date.message}</ErrorText>}
                                </MDBCol>
                                <MDBCol md="2">
                                    <div className="pt-2">File</div>
                                </MDBCol>
                                <MDBCol md="10">
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="file-input"
                                            onChange={(e: any) => {
                                                const files = e.target.files;
                                                files.length > 0 && uploadFile(e.target.files[0]);
                                            }}
                                        />
                                        <label className="custom-file-label" htmlFor="file-input">
                                            {_.isEmpty(fileName) ? '(chọn file)' : fileName}
                                        </label>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <div className='text-center mt-3'>
                        <div>
                            <MDBBtn color='success' onClick={handleSubmit(onSubmit)} disabled={updateDocumentSelector.isLoading}>
                                Xác nhận
                            </MDBBtn>
                        </div>
                        {updateDocumentSelector.isLoading && <MDBSpinner small/>}
                        {updateDocumentSelector.error &&
                        <div
                            className='text-danger'>{formatMessage({id: 'MESSAGE.ERR_0018'}, {_0: 'chứng thư'})}</div>}
                        {updateDocumentSelector.response &&
                        <div
                            className='text-success'>{formatMessage({id: 'MESSAGE.SUCC_0002'}, {_0: 'chứng thư'})}</div>}
                    </div>
                </div>
            }
        </MDBContainer>
    );
}

export default UpdateDocumentContainer;
