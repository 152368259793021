import * as CONST from 'src/core/utils/constants';
import UserInfo from 'src/core/models/user-info.model';
import BaseRepository from 'src/core/repositories/base.repository';

export default class UserRepository extends BaseRepository {
    constructor() {
        super(CONST.ApiURI.TODO);
    }

    getAppUser(userCode: string) {
        return this.query<UserInfo>({userCode}, CONST.ApiURI.GET_APP_USER);
    }
}
