import React, { useEffect, useMemo, useState } from 'react';
import { MDBBtn, MDBCol, MDBContainer, MDBDatePicker, MDBInput, MDBRow, MDBSelect, MDBSpinner } from 'mdbreact';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actGetRequestDetails, actUpdateRequest } from 'src/core/redux/request/request.action';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Company from 'src/core/models/company.model';
import _ from 'lodash';
import ErrorText from 'src/shared/components/error-text/error-text.component';
import { formatDateForApi } from 'src/core/utils/common';
import NumberFormat from 'react-number-format';
import { actGetListCompanies } from 'src/core/redux/company/company.action';
import UpdateRequestRequest from 'src/core/models/requests/update-request-request.model';
import CreateCompanyRequest from 'src/core/models/requests/create-company-request.model';

const UpdateRequestContainer = (): JSX.Element => {
    const {formatMessage} = useIntl();
    const location = useLocation();
    const locationState: any = location.state;
    const dispatch = useDispatch();
    const requestDetailsSelector = useSelector((state: any) => state.requestReducer.requestDetails);
    const requestDetails = requestDetailsSelector.data;
    const companies = useSelector((state: any) => state.companyReducer.companies.data);
    const {register, unregister, handleSubmit, setValue, trigger, formState: {errors}} = useForm();
    const [newCompanyChecked, setNewCompanyChecked] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
    const updateRequestSelector = useSelector((state: any) => state.requestReducer.updateRequest);
    console.log(requestDetails);

    useEffect(() => {
        if (locationState?.requestId) {
            dispatch(actGetRequestDetails(locationState.requestId));
        }
        dispatch(actGetListCompanies());
        registerForm();

        return () => {
            unregister();
        }
    }, []);

    useEffect(() => {
        if (newCompanyChecked) {
            setSelectedCompany(null);
            unregister(['company']);
            registerCompanyForm();
        } else {
            unregister(['companyName', 'taxCode', 'address'])
            registerCompanyField();
        }
    }, [newCompanyChecked]);

    useEffect(() => {
        if (requestDetails) {
            setSelectedCompany(requestDetails.company);
            setValue('company', requestDetails.company.id);
            setValue('receivedDate', requestDetails.receivedDate);
            setValue('inspectionFee', requestDetails.inspectionFee);
            setValue('transportVehicle', requestDetails.transportVehicle);
        }
    }, [requestDetails]);

    const registerCompanyForm = () => {
        register('companyName', {
            required: formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: 'Tên công ty'}),
            maxLength: {
                value: 255,
                message: formatMessage({id: 'MESSAGE.ERR_0011'}, {
                    _0: 'Tên công ty',
                    _1: 255
                }),
            }
        });
        register('taxCode', {
            required: formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: 'Mã số thuế'}),
            maxLength: {
                value: 20,
                message: formatMessage({id: 'MESSAGE.ERR_0011'}, {
                    _0: 'Mã số thuế',
                    _1: 20
                }),
            }
        });
        register('address', {
            required: formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: 'Địa chỉ'}),
            maxLength: {
                value: 255,
                message: formatMessage({id: 'MESSAGE.ERR_0011'}, {
                    _0: 'Địa chỉ',
                    _1: 255
                }),
            }
        });
    }

    const registerCompanyField = () => {
        register('company', {
            required: formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: 'Công ty'}),
        });
    }

    const registerForm = () => {
        register('receivedDate', {
            required: formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: 'Ngày nhận'}),
        });
        register('inspectionFee');
        register('transportVehicle');
    }

    const handleChangeInput = async (name: string, value: any) => {
        setValue(name, value);
        await trigger(name);
    }

    const onSubmit = (data: any) => {
        const request = new UpdateRequestRequest(data.receivedDate, Number(data.inspectionFee), data.transportVehicle);
        if (newCompanyChecked) {
            request.company = new CreateCompanyRequest(data.companyName, data.taxCode, data.address);
        } else {
            request.companyId = Number(data.company);
        }
        dispatch(actUpdateRequest(requestDetails.id, request));
    }

    const companyOptions = useMemo(() => {
        const items = [];
        if (!_.isEmpty(companies)) {
            for (const company of companies) {
                items.push({
                    text: company.name,
                    value: String(company.id),
                    checked: selectedCompany != null && selectedCompany.id === company.id
                });
            }
        }
        return items;
    }, [companies, selectedCompany]);

    const onChangeCompany = (companyId: number) => {
        const company = _.find(companies, c => c.id === companyId);
        company && setSelectedCompany(company);
    }

    return (
        <div id='request-details'>
            <MDBContainer className='pb-4'>
                <div className='text-center'>
                    <h1 className='page-title'>Chỉnh sửa yêu cầu</h1>
                </div>
                {!requestDetails || requestDetailsSelector.isLoading ?
                    <div className='m-5 text-center'>
                        <MDBSpinner/>
                    </div>
                    :
                    <div>
                        <MDBRow>
                            <MDBCol size='12' className='mx-auto'>
                                <p className="font-weight-normal mt-4">THÔNG TIN CÔNG TY</p>
                                <MDBRow className='mt-3'>
                                    <MDBInput
                                        onClick={() => setNewCompanyChecked(true)}
                                        checked={newCompanyChecked}
                                        label="Tạo mới"
                                        type="radio"
                                        id="new-company-radio"
                                    />
                                    <MDBInput
                                        onClick={() => setNewCompanyChecked(false)}
                                        checked={!newCompanyChecked}
                                        label="Khách hàng cũ"
                                        type="radio"
                                        id="old-company-radio"
                                    />
                                </MDBRow>
                                {newCompanyChecked ?
                                    <MDBRow className='mt-2'>
                                        <MDBCol md="2">
                                            <div className="pt-2">Tên công ty</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <MDBInput
                                                placeholder='Tên công ty'
                                                onChange={(e: any) => handleChangeInput('companyName', e.target.value)}
                                                containerClass="my-0 max-width-500"
                                            />
                                            {errors.companyName && <ErrorText>{errors.companyName.message}</ErrorText>}
                                        </MDBCol>
                                        <MDBCol md="2">
                                            <div className="pt-2">Mã số thuế</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <MDBInput
                                                placeholder='Mã số thuế'
                                                onChange={(e: any) => handleChangeInput('taxCode', e.target.value)}
                                                containerClass="my-0 max-width-500"
                                            />
                                            {errors.taxCode && <ErrorText>{errors.taxCode.message}</ErrorText>}
                                        </MDBCol>
                                        <MDBCol md="2">
                                            <div className="pt-2">Địa chỉ</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <MDBInput
                                                placeholder='Địa chỉ'
                                                onChange={(e: any) => handleChangeInput('address', e.target.value)}
                                                containerClass="my-0 max-width-500"
                                            />
                                            {errors.address && <ErrorText>{errors.address.message}</ErrorText>}
                                        </MDBCol>
                                    </MDBRow>
                                    :
                                    <MDBRow className='mt-2'>
                                        <MDBCol md="12">
                                            <MDBSelect
                                                options={companyOptions}
                                                search
                                                selected='(chọn công ty)'
                                                getValue={value => {
                                                    if (value.length > 0) {
                                                        handleChangeInput('company', value[0]);
                                                        onChangeCompany(Number(value[0]));
                                                    }
                                                }}
                                                className='my-0 max-width-500'
                                            />
                                            {errors.company && <ErrorText>{errors.company.message}</ErrorText>}
                                        </MDBCol>
                                        <MDBCol md="2">
                                            <div className="py-2">Tên công ty</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div className="py-2">{selectedCompany?.name}</div>
                                        </MDBCol>
                                        <MDBCol md="2">
                                            <div className="py-2">Mã số thuế</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div className="py-2">{selectedCompany?.taxCode}</div>
                                        </MDBCol>
                                        <MDBCol md="2">
                                            <div className="py-2">Địa chỉ</div>
                                        </MDBCol>
                                        <MDBCol md="10">
                                            <div className="py-2">{selectedCompany?.address}</div>
                                        </MDBCol>
                                    </MDBRow>
                                }

                                <p className="font-weight-normal mt-4">THÔNG TIN YÊU CẦU</p>
                                <MDBRow className='mt-3'>
                                    <MDBCol md="2">
                                        <div className='py-2'>Mã yêu cầu</div>
                                    </MDBCol>
                                    <MDBCol md="10">
                                        <div className='font-weight-bold text-primary py-2'>{requestDetails.code}</div>
                                    </MDBCol>
                                    <MDBCol md="2">
                                        <div className='py-2'>Loại hình</div>
                                    </MDBCol>
                                    <MDBCol md="10">
                                        <div className='py-2'>{requestDetails.requestType.name}</div>
                                    </MDBCol>
                                    <MDBCol md="2">
                                        <div className="pt-2">Ngày nhận</div>
                                    </MDBCol>
                                    <MDBCol md="10">
                                        <MDBDatePicker
                                            clearable
                                            format='DD-MM-YYYY'
                                            autoOk
                                            getValue={(e) => handleChangeInput('receivedDate', e ? formatDateForApi(e) : null)}
                                            className='mt-0 mb-2'
                                            valueDefault={new Date(requestDetails.receivedDate)}
                                            emptyLabel='(chọn ngày)'
                                        />
                                        {errors.receivedDate && <ErrorText>{errors.receivedDate.message}</ErrorText>}
                                    </MDBCol>
                                    <MDBCol md="2">
                                        <div className="pt-2">Phí giám định</div>
                                    </MDBCol>
                                    <MDBCol md="10">
                                        <div className='md-form my-0 max-width-500'>
                                            <NumberFormat
                                                className='form-control'
                                                thousandSeparator={true} suffix=' VND'
                                                onValueChange={({value}) => handleChangeInput('inspectionFee', value)}
                                                defaultValue={requestDetails.inspectionFee}
                                            />
                                        </div>
                                        {errors.inspectionFee && <ErrorText>{errors.inspectionFee.message}</ErrorText>}
                                    </MDBCol>
                                    <MDBCol md="2">
                                        <div className="pt-2">Phương tiện vận tải</div>
                                    </MDBCol>
                                    <MDBCol md="10">
                                        <MDBInput
                                            placeholder='Phương tiện vận tải'
                                            onChange={(e: any) => handleChangeInput('transportVehicle', e.target.value)}
                                            containerClass="my-0 max-width-500"
                                            valueDefault={requestDetails.transportVehicle}
                                        />
                                        {errors.transportVehicle && <ErrorText>{errors.transportVehicle.message}</ErrorText>}
                                    </MDBCol>
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                        <div className='text-center mt-3'>
                            <div>
                                <MDBBtn color='success' onClick={handleSubmit(onSubmit)} disabled={updateRequestSelector.isLoading}>
                                    Xác nhận
                                </MDBBtn>
                            </div>
                            {updateRequestSelector.isLoading && <MDBSpinner small/>}
                            {updateRequestSelector.error &&
                            <div
                                className='text-danger'>{formatMessage({id: 'MESSAGE.ERR_0018'}, {_0: 'yêu cầu'})}</div>}
                            {updateRequestSelector.response &&
                            <div
                                className='text-success'>{formatMessage({id: 'MESSAGE.SUCC_0002'}, {_0: 'yêu cầu'})}</div>}
                        </div>
                    </div>
                }
            </MDBContainer>
        </div>
    );
}


export default UpdateRequestContainer;
