import { AnyAction } from 'redux';
import * as Types from 'src/core/redux/company/company.type';
import { createReducer } from 'src/core/utils/redux';
import CompanyState from 'src/core/models/redux/company-state.model';

const initialState: CompanyState = {
    companies: {
        data: [],
        error: null,
        isLoading: false,
    },
};

const companyReducer = createReducer(initialState, {
    [Types.GET_LIST_COMPANIES_REQUEST]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            companies: {
                ...state.companies,
                isLoading: true,
            }
        };
    },
    [Types.GET_LIST_COMPANIES_SUCCESS]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            companies: {
                data: action.payload,
                error: null,
                isLoading: false
            }
        };
    },
    [Types.GET_LIST_COMPANIES_FAILURE]: (state: typeof initialState, action: AnyAction) => {
        return {
            ...state,
            companies: {
                ...state.companies,
                error: action.payload,
                isLoading: false
            }
        };
    },
})

export default companyReducer;
