import React from 'react';
import 'src/shared/assets/scss/App.scss';
import { Redirect, Switch } from 'react-router-dom';
import * as COMMON from 'src/core/utils/common';
import * as ROUTES from 'src/core/utils/routes';
import * as CONST from 'src/core/utils/constants';

function App(): JSX.Element {
    return (
        <>
            <Switch>
                <Redirect
                    exact
                    from={CONST.AppURI.INDEX}
                    to={CONST.AppURI.REQUESTS}
                />
                {COMMON.buildRoutes(ROUTES.mainRoutes)}
                {COMMON.buildRoutes(ROUTES.appRoutesWithoutNav)}
                <Redirect to={CONST.AppURI.NOT_FOUND} />
            </Switch>
        </>
    );
}

export default App;
