import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { MDBBtn, MDBCol, MDBContainer, MDBDatePicker, MDBInput, MDBRow, MDBSelect, MDBSpinner } from 'mdbreact';
import { useForm } from 'react-hook-form';
import ErrorText from 'src/shared/components/error-text/error-text.component';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateForApi } from 'src/core/utils/common';
import _ from 'lodash';
import { actGetListRequestTypes } from 'src/core/redux/request-type/request-type.action';
import { actGetListCompanies } from 'src/core/redux/company/company.action';
import Company from 'src/core/models/company.model';
import CreateRequestRequest from 'src/core/models/requests/create-request-request.model';
import CreateCompanyRequest from 'src/core/models/requests/create-company-request.model';
import CreateSuccessModal
    from 'src/features/components/request/create-success-modal/create-success-modal.component';
import { actCreateRequest } from 'src/core/redux/request/request.action';
import NumberFormat from 'react-number-format';

const CreateRequestContainer = (): JSX.Element => {
    const {formatMessage} = useIntl();
    const dispatch = useDispatch();
    const {register, unregister, handleSubmit, setValue, trigger, formState: {errors}} = useForm();
    const companies = useSelector((state: any) => state.companyReducer.companies.data);
    const requestTypes = useSelector((state: any) => state.requestTypeReducer.requestTypes.data);
    const createRequestSelector = useSelector((state: any) => state.requestReducer.createRequest);
    const [newCompanyChecked, setNewCompanyChecked] = useState(true);
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
    const [isVisibleCreateSuccessModal, setIsVisibleCreateSuccessModal] = useState(false);
    const [createdRequestCode, setCreatedRequestCode] = useState('');

    useEffect(() => {
        dispatch(actGetListRequestTypes());
        dispatch(actGetListCompanies());
        registerForm();
        return () => {
            unregister();
        }
    }, []);

    useEffect(() => {
        if (newCompanyChecked) {
            setSelectedCompany(null);
            unregister(['company']);
            registerCompanyForm();
        } else {
            unregister(['companyName', 'taxCode', 'address'])
            registerCompanyField();
        }
    }, [newCompanyChecked]);

    const companyOptions = useMemo(() => {
        const items = [];
        if (!_.isEmpty(companies)) {
            for (const company of companies) {
                items.push({
                    text: company.name,
                    value: String(company.id),
                    checked: selectedCompany != null && selectedCompany.id === company.id
                });
            }
        }
        return items;
    }, [companies, selectedCompany]);

    const requestTypeOptions = useMemo(() => {
        const items = [];
        if (!_.isEmpty(requestTypes)) {
            for (const requestType of requestTypes) {
                items.push({
                    text: requestType.name,
                    value: String(requestType.id),
                });
            }
        }
        return items;
    }, [requestTypes]);

    const registerCompanyForm = () => {
        register('companyName', {
            required: formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: 'Tên công ty'}),
            maxLength: {
                value: 255,
                message: formatMessage({id: 'MESSAGE.ERR_0011'}, {
                    _0: 'Tên công ty',
                    _1: 255
                }),
            }
        });
        register('taxCode', {
            required: formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: 'Mã số thuế'}),
            maxLength: {
                value: 20,
                message: formatMessage({id: 'MESSAGE.ERR_0011'}, {
                    _0: 'Mã số thuế',
                    _1: 20
                }),
            }
        });
        register('address', {
            required: formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: 'Địa chỉ'}),
            maxLength: {
                value: 255,
                message: formatMessage({id: 'MESSAGE.ERR_0011'}, {
                    _0: 'Địa chỉ',
                    _1: 255
                }),
            }
        });
    }

    const registerCompanyField = () => {
        register('company', {
            required: formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: 'Công ty'}),
        });
    }

    const registerForm = () => {
        register('receivedDate', {
            required: formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: 'Ngày nhận'}),
        });
        register('requestType', {
            required: formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: 'Loại hình'}),
        });
        register('inspectionFee');
        register('transportVehicle');
    }

    const handleChangeInput = async (name: string, value: any) => {
        setValue(name, value);
        await trigger(name);
    }

    const onSubmit = (data: any) => {
        const request = new CreateRequestRequest(data.receivedDate, Number(data.requestType), Number(data.inspectionFee), data.transportVehicle);
        if (newCompanyChecked) {
            request.company = new CreateCompanyRequest(data.companyName, data.taxCode, data.address);
        } else {
            request.companyId = Number(data.company);
        }
        dispatch(actCreateRequest(request, (requestCode) => {
            setCreatedRequestCode(requestCode);
            setIsVisibleCreateSuccessModal(true);
        }));
    }

    const onChangeCompany = (companyId: number) => {
        const company = _.find(companies, c => c.id === companyId);
        company && setSelectedCompany(company);
    }

    return (
        <MDBContainer>
            <h1 className='page-title mb-4'>Tạo yêu cầu</h1>
            <MDBRow>
                <MDBCol sm='12' md='10' lg='8' xl='6' className='mx-auto'>
                    <p className="font-weight-normal mt-4">THÔNG TIN CÔNG TY</p>
                    <MDBRow className='mt-3'>
                        <MDBInput
                            onClick={() => setNewCompanyChecked(true)}
                            checked={newCompanyChecked}
                            label="Tạo mới"
                            type="radio"
                            id="new-company-radio"
                        />
                        <MDBInput
                            onClick={() => setNewCompanyChecked(false)}
                            checked={!newCompanyChecked}
                            label="Khách hàng cũ"
                            type="radio"
                            id="old-company-radio"
                        />
                    </MDBRow>
                    {newCompanyChecked ?
                        <MDBRow className='mt-2'>
                            <MDBCol md="4">
                                <div className="pt-2">Tên công ty</div>
                            </MDBCol>
                            <MDBCol md="8">
                                <MDBInput
                                    placeholder='Tên công ty'
                                    onChange={(e: any) => handleChangeInput('companyName', e.target.value)}
                                    containerClass="my-0"
                                />
                                {errors.companyName && <ErrorText>{errors.companyName.message}</ErrorText>}
                            </MDBCol>
                            <MDBCol md="4">
                                <div className="pt-2">Mã số thuế</div>
                            </MDBCol>
                            <MDBCol md="8">
                                <MDBInput
                                    placeholder='Mã số thuế'
                                    onChange={(e: any) => handleChangeInput('taxCode', e.target.value)}
                                    containerClass="my-0"
                                />
                                {errors.taxCode && <ErrorText>{errors.taxCode.message}</ErrorText>}
                            </MDBCol>
                            <MDBCol md="4">
                                <div className="pt-2">Địa chỉ</div>
                            </MDBCol>
                            <MDBCol md="8">
                                <MDBInput
                                    placeholder='Địa chỉ'
                                    onChange={(e: any) => handleChangeInput('address', e.target.value)}
                                    containerClass="my-0"
                                />
                                {errors.address && <ErrorText>{errors.address.message}</ErrorText>}
                            </MDBCol>
                        </MDBRow>
                        :
                        <MDBRow className='mt-2'>
                            <MDBCol md="12">
                                <MDBSelect
                                    options={companyOptions}
                                    search
                                    selected='(chọn công ty)'
                                    getValue={value => {
                                        if (value.length > 0) {
                                            handleChangeInput('company', value[0]);
                                            onChangeCompany(Number(value[0]));
                                        }
                                    }}
                                    className='my-0'
                                />
                                {errors.company && <ErrorText>{errors.company.message}</ErrorText>}
                            </MDBCol>
                            <MDBCol md="4">
                                <div className="py-2">Tên công ty</div>
                            </MDBCol>
                            <MDBCol md="8">
                                <div className="py-2">{selectedCompany?.name}</div>
                            </MDBCol>
                            <MDBCol md="4">
                                <div className="py-2">Mã số thuế</div>
                            </MDBCol>
                            <MDBCol md="8">
                                <div className="py-2">{selectedCompany?.taxCode}</div>
                            </MDBCol>
                            <MDBCol md="4">
                                <div className="py-2">Địa chỉ</div>
                            </MDBCol>
                            <MDBCol md="8">
                                <div className="py-2">{selectedCompany?.address}</div>
                            </MDBCol>
                        </MDBRow>
                    }

                    <p className="font-weight-normal mt-4">THÔNG TIN YÊU CẦU</p>
                    <MDBRow className='mt-3'>
                        <MDBCol md="4">
                            <div className="pt-2">Ngày nhận</div>
                        </MDBCol>
                        <MDBCol md="8">
                            <MDBDatePicker
                                clearable
                                format='DD-MM-YYYY'
                                autoOk
                                getValue={(e) => handleChangeInput('receivedDate', e ? formatDateForApi(e) : null)}
                                className='mt-0 mb-2'
                                valueDefault={null as any}
                                emptyLabel='(chọn ngày)'
                            />
                            {errors.receivedDate && <ErrorText>{errors.receivedDate.message}</ErrorText>}
                        </MDBCol>
                        <MDBCol md="4">
                            <div className="pt-3">Loại hình</div>
                        </MDBCol>
                        <MDBCol md="8">
                            <MDBSelect
                                options={requestTypeOptions}
                                selected='(chọn loại hình)'
                                getValue={value => {
                                    if (value.length > 0) {
                                        handleChangeInput('requestType', value[0]);
                                    }
                                }}
                                className='my-0'
                            />
                            {errors.requestType && <ErrorText>{errors.requestType.message}</ErrorText>}
                        </MDBCol>
                        <MDBCol md="4">
                            <div className="pt-2">Phí giám định</div>
                        </MDBCol>
                        <MDBCol md="8">
                            <div className='md-form my-0'>
                                <NumberFormat
                                    className='form-control'
                                    thousandSeparator={true} suffix=' VND'
                                    onValueChange={({value}) => handleChangeInput('inspectionFee', value)}
                                />
                            </div>
                            {errors.inspectionFee && <ErrorText>{errors.inspectionFee.message}</ErrorText>}
                        </MDBCol>
                        <MDBCol md="4">
                            <div className="pt-2">Phương tiện vận tải</div>
                        </MDBCol>
                        <MDBCol md="8">
                            <MDBInput
                                placeholder='Phương tiện vận tải'
                                onChange={(e: any) => handleChangeInput('transportVehicle', e.target.value)}
                                containerClass="my-0"
                            />
                            {errors.transportVehicle && <ErrorText>{errors.transportVehicle.message}</ErrorText>}
                        </MDBCol>
                    </MDBRow>
                </MDBCol>
            </MDBRow>

            <div className='text-center mt-3'>
                <div>
                    <MDBBtn color='success' onClick={handleSubmit(onSubmit)} disabled={createRequestSelector.isLoading}>
                        Xác nhận
                    </MDBBtn>
                </div>
                {createRequestSelector.isLoading && <MDBSpinner small/>}
                {createRequestSelector.error &&
                <div
                    className='text-danger'>{formatMessage({id: 'MESSAGE.ERR_0018'}, {_0: 'yêu cầu'})}</div>}
            </div>
            <CreateSuccessModal
                isVisible={isVisibleCreateSuccessModal}
                setIsVisible={setIsVisibleCreateSuccessModal}
                message='Tạo thành công yêu cầu'
                code={createdRequestCode}
                onClickOk={() => history.back()}
            />
        </MDBContainer>
    );
}

export default CreateRequestContainer;
