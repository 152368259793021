import { ApiURI } from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import Company from 'src/core/models/company.model';

export default class CompanyRepository extends BaseRepository {
    constructor() {
        super(ApiURI.COMPANIES);
    }

    getListCompanies() {
        return this.getAll<Company>();
    }
}
