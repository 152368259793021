import * as Types from 'src/core/redux/company/company.type';
import Repositories, { RepositoryName } from 'src/core/repositories/repository-factory';

const companyRepository = Repositories[RepositoryName.COMPANY];

export const actGetListCompanies = () => (dispatch: any): void => {
    dispatch({
        types: [Types.GET_LIST_COMPANIES_REQUEST, Types.GET_LIST_COMPANIES_SUCCESS, Types.GET_LIST_COMPANIES_FAILURE],
        callAPI: () => companyRepository.getListCompanies(),
    });
};
