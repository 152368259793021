import i18n_en from 'src/shared/i18n/en.json';
import i18n_vi from 'src/shared/i18n/vi.json';

export const DEFAULT_PAGE = 0;
export const DEFAULT_PAGE_SIZE = 5;
export const DEFAULT_SORT_TYPE = 'DESC';
export const DEFAULT_SORT_PROPERTY = 'id';
export const NUMBER_PATTERN = '^[0-9]*[0-9,.]*[0-9]$';
export const NATURAL_NUMBER_PATTERN = '^[0-9]*$';
export const NATURAL_NUMBER_GREATER_THAN_ZERO_PATTERN = '^[1-9]+[0-9]*$';
export const NUMBER_DECIMAL = '^([-+0-9.]+)';
export const DATE_PATTERN = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
export const EMAIL_PATTERN = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const CONVERSATION_REPLACE_PATTERN_1 = /[,.!;"?'’]/g;
export const CONVERSATION_REPLACE_PATTERN_2 = /[-]/g;
export const MAX_INTEGER_VALUE = 2147483647;
export const MIN_INTEGER_VALUE = -2147483647;
export const TZ_SERVER = 'UTC';
export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATETIME_FORMAT = 'DD/MM/YYYY HH:mm:ss';
export const DATETIME_MIN_FORMAT = 'DD/MM/YYYY HH:mm';
export const DATETIME_FORMAT_ISO = 'YYYY-MM-DDTHH:mm:ss.sssZZ';
export const HEX_VALUES = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e'];
export const DEFAULT_LANGUAGE = 'vi';
export const USER_CODE_PREFIX = 'K-'
export const PRIVATE_BUCKET = 'vietchaucontrol-private';
export const PUBLIC_BUCKET = 'vietchaucontrol-public';
export const PUBLIC_BUCKET_PREFIX = 'https://storage.googleapis.com/vietchaucontrol-public/';

export const I18nConfig = {
    en: i18n_en,
    vi: i18n_vi
};

export const ApiURI = {
    TODO: 'todos',
    OAUTH_TOKEN: '/oauth/token',

    GET_UPLOAD_SIGNED_URL: 'cloud/storage/getUploadSignedUrl',

    GET_LIST_CITY: '/guest/city/getListCity',
    UNSUBSCRIBE_ALL_TOPIC: '/guest/unsubscribeAllTopics',

    GET_USER_INFO: '/user/getUserInfo',
    CREATE_FIREBASE_TOKEN: '/user/firebaseToken/createFirebaseToken',

    GET_APP_USER: '/admin/user/getAppUser',

    // guest
    GUEST_DOCUMENT: '/guest/document/documents',

    // user
    REQUESTS: '/request/requests',
    REQUEST_TYPES: '/request/requestTypes',
    DOCUMENTS: '/request/documents',
    COMPANIES: '/company/companies'

}

export const AppURI = {
    INDEX: '/',

    // public
    NOT_FOUND: '/404',
    LOGIN: '/login',
    PUBLIC_DOCUMENT: '/public/document/:documentCode',

    // private
    HOME: '/home',
    SUPPORT: '/support',
    SUPPORT_TICKET_DETAILS: '/support-ticket',
    SPEAKING_TOPICS: '/speaking-topics',
    POST: '/post',
    CREATE_POST: '/create-post',
    REQUESTS: '/requests',
    CREATE_REQUEST: '/create-request',
    REQUEST_DETAILS: '/request-details/:requestId',
    UPDATE_REQUEST: '/update-request',
    DOCUMENT_DETAILS: '/document-details/:documentId',
    DOCUMENTS: '/documents',
    CREATE_DOCUMENT: '/create-document',
    UPDATE_DOCUMENT: '/update-document'
};

export const HttpStatus = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED: 402,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    REQUEST_TIMEOUT: 408,
    INTERNAL_SERVER_ERROR: 500,
    SERVICE_UNAVAILABLE: 503,
};

export const LocalStorage = {
    LOGIN_INFO: 'login_info',
    USER_INFO: 'user_info',
    FCM_TOKEN: 'fcm_token'
};

export const Authority = {
    SYS_ADMIN: 'SYS_ADMIN',
    AGENT_ADMIN: 'AGENT_ADMIN'
};

export const ResponseType = {
    MESSAGE_SUCCESS: 'SUCCESS',
    MESSAGE_INFO: 'INFO',
    MESSAGE_WARNING: 'WARNING',
    MESSAGE_ERROR: 'ERROR',
    MESSAGE_REDIRECT: 'REDIRECT'
};

export const RequestStatus: {[key: string]: string} = {
    NEW: 'Mới',
    IN_PROCESS: 'Đang thực hiện',
    FINISHED: 'Hoàn thành'
};

export const DocumentStatus: {[key: string]: string} = {
    IN_PROCESS: 'Đang thực hiện',
    FINISHED: 'Hoàn thành'
};

Object.freeze(ApiURI);
Object.freeze(AppURI);
Object.freeze(HttpStatus);
Object.freeze(LocalStorage);
Object.freeze(I18nConfig);
Object.freeze(Authority);
Object.freeze(ResponseType);
