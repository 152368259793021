import * as CONST from 'src/core/utils/constants';
import BaseRepository from 'src/core/repositories/base.repository';
import Agent from 'src/core/models/agent.model';
import LoginInfo from 'src/core/models/login-info.model';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs';
import UserInfo from 'src/core/models/user-info.model';
import ResponseCustom from 'src/core/models/responses/response-custom.model';
import { ApiURI } from 'src/core/utils/constants';
import DocumentModel from 'src/core/models/document.model';

export default class CommonRepository extends BaseRepository {
    constructor() {
        super(CONST.ApiURI.TODO);
    }

    getListCity(): Promise<AxiosResponse<Agent[]>> {
        return this.getAll(CONST.ApiURI.GET_LIST_CITY);
    }

    login(userName: string, password: string): Promise<AxiosResponse<LoginInfo>> {
        const data = qs.stringify({
            username: userName,
            password: password,
            scope: 'read',
            grant_type: 'password'
        });

        const config: AxiosRequestConfig = {
            method: 'post',
            url: `${process.env.REACT_APP_BASE_URL}${CONST.ApiURI.OAUTH_TOKEN}`,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': process.env.REACT_APP_BASIC_AUTH
            },
            data: data
        };

        return this.customConfig(config);
    }

    getUserInfo(): Promise<AxiosResponse<UserInfo>> {
        return this.getAll<UserInfo>(CONST.ApiURI.GET_USER_INFO);
    }

    createFirebaseToken(deviceToken: string): Promise<AxiosResponse<ResponseCustom>> {
        return this.create<ResponseCustom>({ deviceToken }, CONST.ApiURI.CREATE_FIREBASE_TOKEN);
    }

    unsubscribeAllTopics(deviceToken: string): Promise<AxiosResponse<ResponseCustom>> {
        return this.create<ResponseCustom>({ deviceToken }, CONST.ApiURI.UNSUBSCRIBE_ALL_TOPIC);
    }

    getDocumentForGuest(code: string): Promise<AxiosResponse<DocumentModel>> {
        return this.axiosClient().get(`${ApiURI.GUEST_DOCUMENT}?code=${code}`);
    }
}
