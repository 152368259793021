import CommonRepository from 'src/core/repositories/common.repository';
import UserRepository from 'src/core/repositories/user.repository';
import CloudStorageRepository from 'src/core/repositories/cloud-storage.repository';
import RequestRepository from 'src/core/repositories/request.repository';
import CompanyRepository from 'src/core/repositories/company.repository';

export enum RepositoryName {
    CLOUD_STORAGE = 'CLOUD_STORAGE',
    COMMON = 'COMMON',
    USER = 'USER',
    REQUEST = 'REQUEST',
    COMPANY = 'COMPANY',
}

type RepositoryValue = {
    [RepositoryName.CLOUD_STORAGE]: CloudStorageRepository,
    [RepositoryName.COMMON]: CommonRepository,
    [RepositoryName.USER]: UserRepository,
    [RepositoryName.REQUEST]: RequestRepository,
    [RepositoryName.COMPANY]: CompanyRepository,
}

type RepositoryType = {
    [key in RepositoryName]: RepositoryValue[key];
}

const Repositories: RepositoryType = {
    [RepositoryName.CLOUD_STORAGE]: new CloudStorageRepository(),
    [RepositoryName.COMMON]: new CommonRepository(),
    [RepositoryName.USER]: new UserRepository(),
    [RepositoryName.REQUEST]: new RequestRepository(),
    [RepositoryName.COMPANY]: new CompanyRepository(),
};

export default Repositories;
