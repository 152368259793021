import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { MDBBtn, MDBCol, MDBContainer, MDBDatePicker, MDBRow, MDBSpinner } from 'mdbreact';
import { useForm } from 'react-hook-form';
import ErrorText from 'src/shared/components/error-text/error-text.component';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateForApi } from 'src/core/utils/common';
import _ from 'lodash';
import CreateSuccessModal from 'src/features/components/request/create-success-modal/create-success-modal.component';
import { PUBLIC_BUCKET, PUBLIC_BUCKET_PREFIX } from 'src/core/utils/constants';
import { actUploadFile } from 'src/core/redux/cloud-storage/cloud-storage.action';
import CreateDocumentRequest from 'src/core/models/requests/create-document-request.model';
import { actCreateDocument } from 'src/core/redux/document/document.action';
import { useLocation } from 'react-router-dom';
import { actGetRequestDetails } from 'src/core/redux/request/request.action';
import RequestInformation from 'src/features/components/request/request-information/request-information.component';
import 'src/features/pages/create-document/create-document.style.scss';

const CreateDocumentContainer = (): JSX.Element => {
    const location = useLocation();
    const {formatMessage} = useIntl();
    const dispatch = useDispatch();
    const {register, unregister, handleSubmit, setValue, trigger, formState: {errors}} = useForm();
    const createDocumentSelector = useSelector((state: any) => state.documentReducer.createDocument);
    const [isVisibleCreateSuccessModal, setIsVisibleCreateSuccessModal] = useState(false);
    const [createdDocumentCode, setCreatedDocumentCode] = useState('');
    const requestDetails = useSelector((state: any) => state.requestReducer.requestDetails.data);
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        registerForm();
        const state: any = location.state;
        if (state?.requestId) {
            dispatch(actGetRequestDetails(state.requestId));
        }

        return () => {
            unregister();
        }
    }, []);

    const registerForm = () => {
        register('date', {
            required: formatMessage({id: 'MESSAGE.ERR_0001'}, {_0: 'Ngày chứng thư'}),
        });
        register('file');
    }

    const handleChangeInput = async (name: string, value: any) => {
        setValue(name, value);
        await trigger(name);
    }

    const onSubmit = (data: any) => {
        const request = new CreateDocumentRequest(requestDetails.id, data.date, data.file);
        dispatch(actCreateDocument(request, (documentCode) => {
            setCreatedDocumentCode(documentCode);
            setIsVisibleCreateSuccessModal(true);
        }));
    }

    const uploadFile = (file: File) => {
        dispatch(actUploadFile(file, (objectName) => {
            handleChangeInput('file', PUBLIC_BUCKET_PREFIX + encodeURIComponent(objectName));
            setFileName(file.name);
        }, PUBLIC_BUCKET));
    }

    return (
        <MDBContainer>
            <h1 className='page-title mb-4'>Tạo chứng thư</h1>
            {requestDetails &&
                <div>
                    <MDBRow>
                        <MDBCol size='12' className='mx-auto'>
                            <RequestInformation data={requestDetails}/>
                            <div className="font-weight-normal mt-4">THÔNG TIN CHỨNG THƯ</div>
                            <MDBRow className='mt-2'>
                                <MDBCol md="2">
                                    <div className="pt-2">Ngày chứng thư</div>
                                </MDBCol>
                                <MDBCol md="10">
                                    <MDBDatePicker
                                        clearable
                                        format='DD-MM-YYYY'
                                        autoOk
                                        getValue={(e) => handleChangeInput('date', e ? formatDateForApi(e) : null)}
                                        className='mt-0 mb-2'
                                        valueDefault={null as any}
                                        emptyLabel='(chọn ngày)'
                                    />
                                    {errors.date && <ErrorText>{errors.date.message}</ErrorText>}
                                </MDBCol>
                                <MDBCol md="2">
                                    <div className="pt-2">File</div>
                                </MDBCol>
                                <MDBCol md="10">
                                    <div className="custom-file">
                                        <input
                                            type="file"
                                            className="custom-file-input"
                                            id="file-input"
                                            onChange={(e: any) => {
                                                const files = e.target.files;
                                                files.length > 0 && uploadFile(e.target.files[0]);
                                            }}
                                        />
                                        <label className="custom-file-label" htmlFor="file-input">
                                            {_.isEmpty(fileName) ? '(chọn file)' : fileName}
                                        </label>
                                    </div>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>
                    <div className='text-center mt-3'>
                        <div>
                            <MDBBtn color='success' onClick={handleSubmit(onSubmit)} disabled={createDocumentSelector.isLoading}>
                                Xác nhận
                            </MDBBtn>
                        </div>
                        {createDocumentSelector.isLoading && <MDBSpinner small/>}
                        {createDocumentSelector.error &&
                        <div
                            className='text-danger'>{formatMessage({id: 'MESSAGE.ERR_0018'}, {_0: 'chứng thư'})}</div>}
                    </div>
                </div>
            }
            <CreateSuccessModal
                isVisible={isVisibleCreateSuccessModal}
                setIsVisible={setIsVisibleCreateSuccessModal}
                message='Tạo thành công chứng thư'
                code={createdDocumentCode}
                onClickOk={() => history.back()}
            />
        </MDBContainer>
    );
}

export default CreateDocumentContainer;
